<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Entretiens<br/>Pilotez vos évaluations<br/> et optimisez la performance RH</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Simplifiez et optimisez la gestion de vos entretiens professionnels et annuels avec notre solution SIRH sur mesure pour PME et ETI.</H2>
            <h3 class="sous-haut-page">
              Nos fonctionnalités:<br/>

              - Planification et suivi des entretiens: Définissez les objectifs, planifiez les rendez-vous et centralisez les informations.<br/>
              - Évaluation des performances: Utilisez des grilles d'évaluation prédéfinies ou personnalisées et collectez des commentaires précis.<br/>
              - Développement des talents: Définissez des plans d'action individualisés et accompagnez vos collaborateurs dans leur évolution.<br/>
              - Suivi des décisions: Centralisez les décisions prises et les mesures à mettre en place.<br/>
              - Reporting et analyse: Accédez à des tableaux de bord et des indicateurs clés pour une meilleure prise de décision.<br/>
            </h3>
            <h3 class="sous-haut-page">
              Les avantages d'un module de gestion des entretiens sur mesure:<br/>
                - Gain de temps et d'efficacité<br/>
              -Processus d'évaluation standardisé et objectif<br/>
              - Meilleure communication et motivation des collaborateurs<br/>
              - Développement des talents et identification des potentiels<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fentretien.svg?alt=media&token=4e9a1f3c-b83e-4592-9e27-7180b426382b" alt="logiciel entretien">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <presentation-fonction
        titre='Digitalisez les entretiens professionnels et annuels.<br> focalisez-vous sur l’essentiel<br> et automatisez le reste.'
        sousTitre="Planifiez et suivez vos campagnes rapidement en utilisant nos modèles. 5 minutes suffisent pour
                  lancer vos premiers entretiens"
        v-bind:modules='["entretien-creation", "entretien-campagne"]'
        sens="gauche"
        balise="logiciel entretien"
    ></presentation-fonction>

    <presentation-fonction
        titre='Préparez les entretiens<br> et partagez les avec vos collaborateurs si vous le souhaitez.'
        sousTitre="Chaque manager et collaborateur peut décider, lorsque l'entretien est paramétré de partager ou non sa préparation"
        v-bind:modules='["entretien-preparation", "entretien-evaluation"]'
        sens="droite"
        balise="gestion des entretiens professionnels"
    ></presentation-fonction>
    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages de notre module d'entretiens</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Gagnez du temps, digitalisez l'ensemble du processus des entretiens et utilisez les entretiens comme outil de management</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie"  alt="logiciel entretien annuel">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Profitez des entretiens, Devenez acteurs en les préparant pour explorer
              d’avantages des thèmes qui vous tiennent à cœur et suivez clairement l’évolution de vos performances.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="logiciel pour piloter les entretiens">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20" id="preparer">Préparez la trame des entretiens pour vos managers,
              créez des campagnes d’entretien rapidement et suivez leur avancement.<br>
              Suivez en temps réel les statistiques des évaluations et accompagnez la progression de vos personnels
              depuis le logiciel GPEC. Consultez les formations et les obligations légales en termes de formation à partir
              du module de gestion des compétences.</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="des entretiens facilité avec notre logiciel de gestion des entretiens">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Gagnez du temps grâce à la trame d'entretien préétablie,
              planifiez et préparez vos entretiens en évaluant correctement et tout simplement.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Logiciel de gestion des entretiens : <br> un atout pour les entreprises</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
              <img src="./../assets/images/reussir-ces-entretiens.svg" alt="Donnez de la valeur ajoutée aux entretiens">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2>Les fonctionnalités et atouts de notre logiciel de gestion des entretiens</h2>
              <p>- Planification et tenue d'entretiens : Humatch vous aide à planifier des entretiens de manière efficace et à tenir des entretiens de manière structurée.</p>
              <p>- Suivi des décisions : Ce logiciel vous aide à suivre les décisions prises lors des entretiens et à assurer leur mise en œuvre.</p>
              <p>- Base de données des entretiens : Notre module de suivi des entretiens stocke toutes les informations relatives aux entretiens dans une base de données centralisée et accessible à tout moment.</p>
              <p>- Évaluation des performances : Ce logiciel peut aider à évaluer la performance des employés en utilisant des données factuelles.</p>
              <p>- Notification et rappel : l'application de suivi des entretiens peut envoyer des notifications et des rappels pour planifier des entretiens à temps.</p>
              <p>- Reporting et analyse : Ce logiciel peut générer des rapports sur les entretiens et permettre une analyse approfondie des données pour prendre des décisions informées.</p>
              <p>En utilisant notre module de gestion des entretiens, les entreprises peuvent améliorer leur processus d'entretiens, rationaliser le suivi des décisions et améliorer la performance de leurs employés.</p>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="troisColumn padding10 textExplication">
              <h2>Quel intérêt des entretiens annuels d'évaluation pour les professionnels ?</h2>
              <p>
                À la fin de chaque année, il est de coutume que les entreprises fassent le bilan pour voir si les objectifs ont été atteints.
                Même si cela ne concerne le plus souvent que les activités de la structure, ce bilan s'effectue aussi au niveau des
                collaborateurs. On parle dans ce cas d'<strong>entretien d'évaluation</strong>. C'est un procédé qui permet  de faire le point
                sur le travail que chaque employé a réalisé tout au long de l'année. L'entretien annuel d'évaluation consiste donc pour l'entreprise,
                et plus particulièrement pour les ressources humaines, à dialoguer avec chaque collaborateur sur les succès enregistrés,
                les difficultés rencontrées ou sur les conditions de travail.
              </p>
              <p>
                Les entretiens annuels pour évaluer le personnel ont ainsi pour but d'améliorer la performance des salariés, ce qui aura pour
                conséquence de faciliter l'atteinte des objectifs individuels et collectifs de l'organisation.
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2>Pourquoi un logiciel de gestion des entretiens ?</h2>
              <p>
                L'entretien d'évaluation est une tâche assez complexe qui demande énormément de temps. Pour cela, les entreprises utilisent
                des logiciels spécialement dédiés à <strong>la gestion des entretiens annuels</strong>. Il s'agit d'une solution qui permet de
                bénéficier de plusieurs avantages.
              </p>
              <h3>Gérer différents entretiens</h3>
              <p>
                L'entretien d'évaluation peut prendre plusieurs noms selon le fonctionnement et l'organisation d'une entreprise. Il peut être
                question d'entretien d'appréciation, d'entretien professionnel, d'entretien individuel ou d'entretien RH. L'utilisation d'une
                solution de gestion des entretiens comme<strong> le logiciel SIRH de Humatch</strong> permet de mieux appréhender ces
                différentes nuances. L'outil permet de centraliser tous les différents entretiens qui servent à évaluer le personnel afin de
                les gérer efficacement.
              </p>
              <h3>Gagner du temps</h3>
              <p>
                Le gain du temps est l'un des principaux avantages de l'utilisation d'un outil de gestion des entretiens. Ceux-ci sont
                entièrement digitalisés, ce qui en facilite le pilotage. La digitalisation permet de créer des campagnes d'entretien en
                seulement quelques clics. Vous pouvez ainsi concevoir <strong>autant de modules de gestion d'entretien</strong> que vous voulez.
              </p>
              <h3>Développer et consolider le savoir-faire des professionnels</h3>
              <p>
                Les logiciels de gestion d'entretien permettent d'apprécier les compétences des employés afin de proposer des formations
                susceptibles de les développer. L'utilisation d'outils d'entretien professionnel comme ceux de Humatch favorise ainsi le
                développement et la consolidation des savoir-faire au sein de l'entreprise.
              </p>
              <h3>Assurer le suivi de l'évolution des employés</h3>
              <p>
                Les <strong>solutions de gestion d'entretien</strong> facilitent le suivi de l'évolution professionnelle des salariés. Les
                données qui proviennent des modules du logiciel SIRH sont mentionnées dans un tableau de bord de sorte que la gestion des
                talents puisse être simplifiée pour les ressources humaines. En utilisant cet outil, vous monitorez la progression des employés
                et dans le même temps, vous vérifiez si les objectifs sont atteints.
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2>Comment réussir une campagne d'entretien annuel ?</h2>
              <p>
                Utiliser un logiciel pour les entretiens annuels d'évaluation pour améliorer les performances globales
                de l'entreprise. Il y a cependant certaines clés qu'il faut connaître pour <strong>une gestion efficace des campagnes
                d'entretien</strong>. Vous devez notamment :
              </p>
              <ul>
                <li>communiquer avec les collaborateurs sur les objectifs de la campagne,</li>
                <li>bien planifier la campagne d'entretien professionnel annuel (sur une courte durée de préférence),</li>
                <li>identifier les sujets pertinents ou prioritaires,</li>
                <li>informer les collaborateurs sur les thèmes à aborder pendant l'entretien,</li>
                <li>bien échanger pendant l'entretien individuel.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import PresentationFonction from "@/components/presentationFonction";
import simplifier from "@/components/simplifier";

export default {
  name: 'Entretien',
  metaInfo: {
    title: "Module de Gestion des Entretiens - Améliorez la productivité de votre entreprise",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gestion-entretien'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Optimisez la planification et la tenue de vos entretiens avec notre logiciel de gestion des entretiens. Facile à utiliser et efficace, il améliorera la productivité de votre entreprise.'
      }
    ]
  },
  components: { Bottom, Menu, PresentationFonction, simplifier},
  data(){
    return {
      menu:"entretien"
    }
  }
}
</script>
