<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Simplifiez le quotidien de vos ressources humaines avec notre logiciel RH</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gagnez du temps avec notre logiciel RH complet pour PME, Mairies, Associations, permettant à
              votre organisation de gagner en performance.</H2>
          </div>

          <div class="fonction-image marginTop100">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fpourquoi-humatch.svg?alt=media&token=606d4553-27cd-4411-b147-493c990e4372"
                alt="pourquoi humatch">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <section class="boxTarif">
      <div class="container">
        <div class="row">
          <div class="deuxColumn">
            <div v-show="pme===false" style="align-content: center;margin-top:20px;cursor: pointer;" v-on:click="recup(1)">
              Une application et des modules pensés pour les PME
            </div>
            <div v-show="pme===true" class="demandeDemoPourquoi" style="margin-top: 3px">
              <div class="texteDemandeTarif">Une application et des modules pensés pour les PME</div>
            </div>
          </div>
          <div class="deuxColumn">
            <div v-show="association===false" style="align-content: center;margin-top:20px;cursor: pointer" v-on:click="recup(2)">
              Une application et des modules pensés pour les collectivités territoriales
            </div>
            <div v-show="association===true" class="demandeDemoPourquoi" style="margin-top: 3px">
              <div class="texteDemandeTarif">Une application pensée pour les collectivités territoriales</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <section class="liste-fonction" v-show="pme===true">
      <div class="container">
        <div class="row">
          <div class="deuxColumn padding20">
            <div class="row">
              <span class="post-date hidePart">
                Logiciel RH pour PME
              </span>
            </div>
            <div class="row">
              <div class="deuxColumn marginTop20">
                <h2>Une application et des modules pensés pour les PME</h2>
              </div>
            </div>
            <div class="row">
              <div class="demandeDemo marginTop50">
                <router-link to="/demo" class="texteDemandeTarif">Demande de démo</router-link>
              </div>
            </div>
            <div class="row">
              <div class="complete-list">
                <div class="item marginTop20">
                  <div>
                    <p>L’enjeu des directions des ressources humaines des PME est de:</p>
                    <ul>
                      <li>Gagner du temps en supprimant les tâches administratives sans valeur ajoutée.</li>
                      <li>Trouver une solution adaptée en termes de budget et de mise en place</li>
                      <li>Gérer et faire évoluer ses collaborateurs</li>
                    </ul>
                    <p>Notre solution est modulaire, vous pouvez tout utiliser mais pour faciliter l'adoption et l'appropriation de l'application
                      nous vous recommandons de commencer par :</p>
                    <ul>
                      <li>Le module de gestion des entretiens: En effet, cet exercice peut s'avérer complexe et chronophage, définition des trames, lancement des campagnes, etc.
                        De plus, cela demande un travail important de suivi de la part des ressources humaines auprès des managers et de leurs équipes pour s'assurer du bon déroulement des entretiens.</li>
                      <li>Notre solution permet une mise en place ultra rapide, simple et un gain de temps important grâce aux relances automatiques.
                        La marque employeur de l’entreprise est aussi améliorée par une digitalisation du processus.</li>
                      <li>Les collaborateurs, lorsque l’entreprise le souhaite, peuvent préparer leurs entretiens,
                        et partager leur préparation. Souvent les entretiens deviennent un véritable outil de management et de fidélisation des talents.</li>
                      <li>Une fois les entretiens maîtrisés, la direction des ressources humaines peut intégrer un nouveau module comme la mise en place
                        du module recrutement et la digitalisation des intégrations de nouveaux collaborateurs ( onboarding).
                        Chaque module est pensé pour être progressif : d'une utilisation simple à plus poussée.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="fonction-image padding80">
              <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fpme.svg?alt=media&token=6b81b179-2894-49af-ae9f-4b67992bc6bb" alt="logiciel RH pour PME">
            </div>
        </div>
      </div>
    </section>

    <section class="liste-fonction" v-show="association===true">
      <div class="container">
        <div class="row">
          <div class="deuxColumn padding20">
            <div class="row">
              <span class="post-date hidePart">
                Logiciel RH pour collectivités territoriales
              </span>
            </div>
            <div class="row">
              <div class="deuxColumn marginTop20">
                <h2>Une application et des modules pensés pour les collectivités  territoriales</h2>
              </div>
            </div>
            <div class="row">
              <div class="demandeDemo marginTop50">
                <router-link to="/demo" class="texteDemandeTarif">Demande de démo</router-link>
              </div>
            </div>
            <div class="row">
              <div class="complete-list">
                <div class="item marginTop20">
                  <div>
                    <p>Comme pour les entreprises, les collectivités territoriales sont confrontées à des enjeux importants dans la digitalisation.
                      Les services RH des mairies par exemple ont besoin d’outils simples et adaptés à leurs contraintes.
                    </p>
                    <p>
                      Notre application propose des fonctionnalités spécifiques à leurs problématiques. Ainsi le module recrutement permet :
                    </p>
                    <ul>
                      <li>Une centralisation des candidatures quelque soit l’origine du candidat</li>
                      <li>La mise en place d’un référentiel d’annonces types pour gagner du temps</li>
                      <li>Une Cvthèque permettant des recherches avec des filtres spécifiques sur le type de contrat “contractuel” ou non</li>
                      <li>Un publipostage sur Pôle Emploi, Indeed, leur sites internet, mais aussi emploi-territorial.fr</li>
                      <li>Un partage des candidatures avec les élus et la possibilité pour ceux-ci de rédiger des avis</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fonction-image padding80">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fmairie.svg?alt=media&token=4492c68e-b43f-4c6d-9a86-c3713f1d2adb" alt="logiciel RH pour mairie">
          </div>
        </div>
      </div>
    </section>

    <div class="marginBottom20 marginTop20"></div>
    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container">
        <div class="row">
          <div class="sous-titre marginTop100">
            <h2>Gagnez du temps avec notre logiciel RH complet pour PME, Mairies et Associations, permettant à votre
              organisation de gagner en performance.</h2>
          </div>
        </div>
        <div class="row padding80">
          <div class="deuxColumn padding20 block-default-text">
            <h3 class="marginTop20">Le constat</h3>
            <p class="marginTop20 block-text">
              La digitalisation est devenue un enjeu important pour les PME mais aussi pour les collectivités
              territoriales. Plusieurs facteurs expliquent cette tendance de fonds parmi lesquels :
            </p>
            <ul>
              <li>Gagner en productivité en supprimant les tâches automatisables</li>
              <li>Attirer des talents: Dans un contexte en tension sur le marché du travail, la notion de marque
                d’employeur devient un enjeu. Dans ce contexte la digitalisation vous permet de rapidement améliorer
                votre image de marque en vous aidant à ne pas perdre de talents ou dans la cohérence de vos communications.
              </li>
            </ul>
          </div>
          <div class="deuxColumn padding20 block-default-text">
            <h3 class="marginTop20">Les freins à la digitalisation</h3>
            <p class="block-text marginTop20">
              La transformation digitale de votre organisation peut être freinée par plusieurs facteurs:
            </p>
            <ul>
              <li>Une organisation établie qui fonctionne et dont les collaborateurs sont réticents aux changements.
                Pourquoi changer puisque tout fonctionne bien ? Digitaliser demande des modifications de comportement
                des salariés et du management.
              </li>
              <li>Un déficit de compétences numériques: il s’agit plus d’une méconnaissance des outils digitaux et des
                processus de mise en place.
              </li>
              <li>Un coût trop important : la mise en place d’un système d’informations sur toute l’entreprise est
                important et la trésorerie de l’entreprise doit être suffisante.
              </li>
              <li>Le manque d’implication des dirigeants : comme pour l’organisation, la transformation digitale
                bouleverse les méthodes de travail, alors pourquoi changer.
              </li>
            </ul>
          </div>
        </div>
        <div class="row padding80">
          <div class="deuxColumn padding20 block-default-text">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fpourquoi-adopter-humatch.svg?alt=media&token=4b5938c7-169b-4aeb-9c8b-f9ed5773727d"
                alt="pourquoi adopter humatch">
          </div>
          <div class="deuxColumn padding20 block-default-text">
            <h3 class="marginTop20">Pourquoi adopter le logiciel RH Humatch ?</h3>
            <p class="block-text marginTop20">
              Fort de ce constat, Humatch apporte une solution qui propose des fonctionnalités et automatise le travail de l'ensemble de vos collaborateurs.
              Le temps gagné avec notre logiciel RH permet aux équipes RH de se concentrer sur l’accompagnement des équipes et le pilotage des RH.
            </p>
            <ul>
              <li>Un management du changement facilité : Grâce à sa conception modulaire, votre organisation peut progressivement adopter des fonctionnalités.
                Vous pouvez commencer par la gestion des entretiens,  facile à mettre en place  puis lancer d’autres modules.
                A chaque introduction d’un nouveau module, vous capitalisez sur ce que les équipes ont déjà appris
                puisque le fonctionnement du logiciel est toujours le même.
              </li>
              <li>Un logiciel RH pour les utilisateurs: Conçu avec des ergonomes, nous recherchons toujours la
                simplicité d’utilisation pour que l’adoption de la solution soit simple et le plus naturelle possible.
              </li>
              <li>Un accompagnement au changement: Nos consultants fonctionnels sont à votre disposition pour vous aider
                à mettre en place la solution. Mais au-delà de cela, nous proposons toujours un automatisme pour vous
                aider à vous lancer. Ainsi pour les entretiens, nos trames types permettent de mettre en place une
                campagne d’entretien en quelques minutes.
              </li>
              <li>Des coûts simples, progressifs, et abordables: 1€/mois/collaborateur/module.
                Notre tarification permet de ne pas peser sur la trésorerie et de ne payer que ce qui est consommé.
              </li>
            </ul>
          </div>
        </div>
        <div class="row marginBottom20">
          <div class="planifier-button">
              <div class="demandeDemo marginTop50">
                <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link>
              </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";

export default {
  name: 'Pourquoi',
  metaInfo: {
    title: "Logiciel RH - PME - Mairie - Association",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/logiciel-rh-pourquoi'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'les PME, mairie et les associations font face à un enjeux de digitalisation des RH important. Notre logiciel RH les accompagne dans ce processus'
      }
    ]
  },
  components: {Menu, Bottom, simplifier},
  data() {
    return {
      menu: "societe",
      pme: true,
      association: false
    }
  },
  methods: {
    recup(f) {
      this.pme = false;
      this.association = false;
      if (f === 1) {
        this.pme = true
      } else {
        this.association = true;
      }
    }
  }
}
</script>
