<template>
  <div>
    <header>
      <div class="container-fluid">
        <div class="row align-items-center" id="navbar-top">
          <div class="header-logo">
            <router-link to="/"><img src="./../assets/images/logo.svg" alt="Digitalisez vos rh avec notre SIRH"/></router-link>
          </div>
          <div>
            <span></span>
          </div>
          <nav class="header-menu">
          </nav>
          <div class="demandeDemo">
            <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link>
          </div>
          <div class="header-right">
            <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link>
          </div>
        </div>
      </div>
    </header>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text-ad">
            <div class="headline">
              <h1>Gagnez du temps en digitalisant vos entretiens</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Avec notre application vous pouvez créer et suivre vos campagnes d'entretiens en quelques minutes.</H2>
            <h2 class="marginTop20">49 €/mois jusqu'à 50 collaborateurs pour tous les modules</h2>
            <div class="marginBottom20 marginTop20"></div>
            <div class="demandeDemo">
              <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link>
            </div>
            <div class="marginBottom20 marginTop20"></div>
            <div class="block-adword-list">
              <ul>
                <li>Créez vos modèles d'entretiens ou utilisez nos modèles<br><em><small>Chaque modèle d’entretien bénéficie d’un paramétrage possible spécifique. Vous pouvez ainsi choisir de faire valider par le collaborateur, d’envoyer un mail de convocation ou non, de proposer une préparation au collaborateur et un partage de celle-ci. C’est vous qui déterminez le workflow.</small>
                </em></li>
                <li>Lancez une campagne en 3 clics<br><em><small>Vous pouvez générer en un temps record votre campagne en choisissant la période de réalisation, le modèle d’entretien à utiliser, mais aussi la cible en filtrant si vous le souhaitez par établissement(s), managers(s) ou fonction(s). Chaque manager concerné par la campagne peut ensuite planifier ses rendez-vous avec chaque collaborateur.</small></em></li>
                <li>Vos collaborateurs et managers peuvent échanger simplement<br><em><small>Une fois l’entretien planifié, manager et collaborateur ont accès à la trame des sujets et questions abordées. Chacun peut préparer et saisir des éléments de réponse. Il peut également décider de partager sa préparation avec son interlocuteur pour aller encore plus au cœur des choses. Une fois l’entretien réalisé, il ne reste qu’à le valider via votre portail. Le collaborateur peut alors émettre une réserve alertant les RH d’un aléas suite à l’entretien.</small></em></li>
                <li>Suivez en temps réel l'avancement de vos campagnes<br><em><small>Une fois les entretiens terminés, vous souhaitez voir quel est le niveau actuel, les anciens résultats, les objectifs en cours, ou les formations à venir de votre collaborateur ? Toutes ces informations sont mises à jour en temps réel via les saisies de l’entretien.</small></em></li>
              </ul>
            </div>
          </div>
        <div class="marginBottom20 marginTop20"></div>
        <div class="presentation-image-ad">
          <form v-on:submit.prevent="createContact" style="background-color: #F4F5FE">
            <div class="alert" v-if="message.length>0">{{ message }}</div>
            <div class="form-header">
              <br>
              <p>Contactez nous</p>
              <br>
            </div>
            <div class="form-inputs">
              <div class="input-container">
                <label>Nom *</label>
                <input type="text" v-model="contact.lastName" required>
              </div>
              <div class="input-container">
                <label>Prénom *</label><br>
                <input type="text" v-model="contact.firstName" required>
              </div>
              <div class="input-container">
                <label>Email *</label><br>
                <input type="email" v-model="contact.email" required>
              </div>
              <div class="input-container">
                <label>Téléphone *</label><br>
                <input type="text" v-model="contact.phone" required>
              </div>
              <div class="input-container">
                <label>Message</label>
                <textarea rows="5" cols="10" v-model="contact.message" ></textarea>
              </div>
              <div class="submit-container">
                <input type="submit" value="ENVOYER">
              </div>
            </div>
          </form>
        </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages de notre module d'entretiens</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Gagnez du temps, digitalisez l'ensemble du processus des entretiens et utilisez les entretiens comme outil de management</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie"  alt="logiciel entretien annuel">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Profitez des entretiens, Devenez acteurs en les préparant pour explorer
              d’avantages des thèmes qui vous tiennent à cœur et suivez clairement l’évolution de vos performances.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="logiciel pour piloter les entretiens">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20" id="preparer">Préparez la trame des entretiens pour vos managers,
              créez des campagnes d’entretien rapidement et suivez leur avancement.<br>
              Suivez en temps réel les statistiques des évaluations et accompagnez la progression de vos personnels
              depuis le logiciel GPEC. Consultez les formations et les obligations légales en termes de formation à partir
              du module de gestion des compétences.</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="des entretiens facilité avec notre logiciel de gestion des entretiens">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Gagnez du temps grâce à la trame d'entretien préétablie,
              planifiez et préparez vos entretiens en évaluant correctement et tout simplement.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <avantages></avantages>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Bottom from "@/components/Bottom";
import db from '../shared/db';

export default {
  name: 'Demo',
  metaInfo: {
    title: "logiciel de gestion des entretiens annuels et professionnels",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Découvrez notre solution de gestion des entretiens annuels et professionnels'
      }
    ]
  },
  link: [
    {
      rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gestion-entretien'
    }
  ],
  components: {Bottom},
  data(){
    return {
      menu:"demo",
      message: "",
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        menu: 'contact',
      }
    }
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    createContact() {
      db.createContact(this.contact);
      this.contact = {};
      this.message = "Votre message a été envoyé"
    }
  },
  created() {
    this.message = ""
  }
}
</script>


