<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Logiciel de gestion<br> des ressources humaines<br> pour le Retail</h1>
              <span class="line-headline"></span>
            </div>
            <H2></H2>
            <h3 class="sous-haut-page">
              Le secteur du retail fait face à un défi lié à un turnover élevé et des problématiques de motivation des collaborateurs.
              A cela s'ajoutent des contraintes de rentabilité, obligeant le secteur à rechercher en permanence des
              sources d'optimisation.
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fretail.jpeg?alt=media&token=042cb0ec-a3a7-45b0-86b4-3f21fba53806"
                alt="Intégration et rdd de votre SIRH">
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-text propos-block-default-text">
            <h2>Fonctionnalités proposées pour vous accompagner</h2>
            <p>
              Pour accompagner les professionnels du BTP nous proposons les fonctionnalités suivantes :
            </p>
            <ul>
              <li>Création d’une CV Thèque simplifiée permettant ensuite des recherches dans celle-ci de manière
                simple
              </li>
              <li>Gestion des remplacements automatisés : A partir de votre CVthèque ou de votre base collaborateurs,
                vous pouvez proposer des missions et sélectionner les candidats/collaborateurs en quelques clics
              </li>
              <li>Pour l’image de l'employeur, le processus d’intégration est aujourd’hui primordial. Vous pouvez ainsi
                utiliser notre module d’onboarding et permettre de digitaliser la gestion des documents, générer
                automatiquement les contrats et les faire signer électroniquement.
              </li>
              <li>Fidélisez vos talents en planifiant des entretiens de qualité où les préparations de chaque côté sont possibles et partageables lorsque les utilisateurs le souhaitent.
              </li>
              <li>Gérez les compétences pour faire évoluer et fidéliser vos collaborateurs</li>
            </ul>
          </div>
          <div class="block-default-image">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fmission.svg?alt=media&token=648dc458-fc5e-4dcb-a20e-392a9a80a9fd"
                alt="Capitalisez sur vos précédentes missions">
          </div>
        </div>
      </div>
    </section>
    <avantages></avantages>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import avantages from "@/components/avantages";

export default {
  name: 'Retail',
  metaInfo: {
    title: "Logiciel de gestion des RH pour le Retail",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/partenaire'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: ''
      }
    ]
  },
  components: {Menu, Bottom, simplifier, avantages},
  data() {
    return {
      menu: ""
    }
  }
}
</script>
