import Vue from 'vue'
import Meta from 'vue-meta'
import VueRouter from 'vue-router'
import Accueil from '@/components/Accueil'
import Core from '@/components/Core'
import Recrutement from '@/components/Recrutement'
import Onboarding from '@/components/Onboarding'
import Entretien from "@/components/Entretien";
import Gpec from "@/components/Gpec";
import Gta from "@/components/Gta";
import Conges from "@/components/Conges";
import Societe from "@/components/Societe";
import Cgu from "@/components/Cgu";
import Protection from "@/components/Protection";
import Mention from "@/components/Mention";
import Accompagnement from "@/components/Accompagnement";
import Contact from "@/components/Contact";
import Demo from "@/components/Demo";
import Actualites from "@/components/Actualites";
import Article from "@/components/Article";
import Securite from "@/components/Securite";
import NotFound from "@/components/NotFound";
import Pourquoi from "@/components/Pourquoi";
import Nouveautes from "@/components/Nouveautes";
import Remplacement from "@/components/Remplacement";
import Interim from "@/components/Interim";
import Hcr from "@/components/Hcr";
import Ehpads from "@/components/Ehpads";
import Btp from "@/components/Btp";
import Retail from "@/components/Retail";
import Adwords from "@/components/Adwords";
import Pme from "@/components/pme";

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil,
  },
  {
    path: '/sirh/core-rh',
    name: 'Core',
    component: Core,
  },
  {
    path: '/sirh/logiciel-de-recrutement',
    name: 'Recrutement',
    component: Recrutement,
  },
  {
    path: '/sirh/logiciel-integration-onboarding-employes',
    name: 'Onboarding',
    component: Onboarding,
  },
  {
    path: '/sirh/logiciel-gestion-entretien',
    name: 'Entretien',
    component: Entretien,
  },
  {
    path: '/sirh/logiciel-gpec',
    name: 'Gpec',
    component: Gpec,
  },
  {
    path: '/sirh/logiciel-gestion-des-temps',
    name: 'Gta',
    component: Gta,
  },
  {
    path: '/sirh/logiciel-gestion-des-conges',
    name: 'Conges',
    component: Conges,
  },
  {
    path: '/sirh/logiciel-gestion-des-remplacements',
    name: 'Remplacement',
    component: Remplacement,
  },
  {
    path: '/expertises/interim',
    name: 'Interim',
    component: Interim,
  },
  {
    path: '/expertises/hcr',
    name: 'Hcr',
    component: Hcr,
  },
  {
    path: '/expertises/ehpads',
    name: 'Ehpads',
    component: Ehpads,
  },
  {
    path: '/expertises/btp',
    name: 'Btp',
    component: Btp,
  },
  {
    path: '/expertises/retail',
    name: 'Retail',
    component: Retail,
  },
  {
    path: '/logiciel-rh-pourquoi',
    name: 'Pourquoi',
    component: Pourquoi,
  },
  {
    path: '/societe',
    name: 'Societe',
    component: Societe,
  },
  {
    path: '/cgu',
    name: 'Cgu',
    component: Cgu,
  },
  {
    path: '/protection-donnees',
    name: 'Protection',
    component: Protection,
  },
  {
    path: '/mentions-legales',
    name: 'Mention',
    component: Mention,
  },
  {
    path: '/accompagnement',
    name: 'Accompagnement',
    component: Accompagnement,
  },
  {
    path: '/securite',
    name: 'Securite',
    component: Securite,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
  {
    path: '/actualite-digitalisez-vos-rh',
    name: 'Actualites',
    component: Actualites
  },
  {
    path: '/actualite-rh/:slug',
    name: 'Article',
    component: Article
  },
  {
    path: '/nouveautes-produit',
    name: 'Nouveautes',
    component: Nouveautes
  },
  {
    path: '/logiciel-gestion-des-entretiens',
    name: 'Adwords',
    component: Adwords
  },
  {
    path: '/sirh/pme',
    name: "pme",
    component: Pme
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

