<template>
  <div>
    <Menu></Menu>
    <section class="top-section">
      <div class="container textDescription">
        <div class="top-section-text">
          <h1>Mentions légales</h1>
          <p class="textDescription">Editeur : Humatch</p>
        </div>
      </div>
    </section>
    <section class="easy-to-create-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-text ">
            <h3>Editeur: HUMATCH</h3><br><br>
            <p>63 rue andré bollier</p>
            <p>69007 LYON</p>
            <p>RCS LYON n°852 399 724</p>
            <p>email: contact@humatch.fr</p>
            <p>Tel: 04 27 86 15 53</p>
            <p>Directeur de la publication: Chrys VLACHOU</p>
          </div>
          <div class="block-default-text margin-left-default">
            <h3>Hébergeur Site Internet</h3><br><br>
            <p>Google Cloud Europe</p><br><br>
            <h3>Hébergeur Application</h3><br><br>
            <p>AWS Paris</p><br><br>
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Mention',
  metaInfo: {
    title: "mentions légales - humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/mentions-legales'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Les mentions légales d\'Humatch'
      }
    ]
  },
  data(){
    return {
      menu:"mention"
    }
  },
  components: {Menu, Bottom}
}
</script>
