<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Gestion des Dossiers RH:<br/> Centralisez et sécurisez vos données RH</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Optimisez la gestion de vos documents RH et simplifiez l'accès aux informations de vos collaborateurs avec notre solution SIRH sur mesure pour PME et ETI.</H2>
            <h3 class="sous-haut-page">
              Nos fonctionnalités:<br/>

              - Stockage sécurisé des documents: Centralisez tous vos documents RH (contrats de travail, formations, etc.) dans un coffre-fort numérique sécurisé.<br/>
              - Numérisation et archivage: Digitalisez vos documents papier et accédez à vos archives en quelques clics.<br/>
              - Partage et collaboration: Donnez aux collaborateurs et aux managers l'accès aux documents dont ils ont besoin.<br/>
              - Gestion des droits d'accès: Définissez des droits d'accès granulaires pour garantir la confidentialité des données.<br/>
              - Recherche et consultation: Retrouvez facilement les documents dont vous avez besoin grâce à un moteur de recherche performant.<br/>
            </h3>
            </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fcore-rh.svg?alt=media&token=313bd810-a437-4155-8f36-75e369e65e57" alt="logiciel core RH">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <presentation-fonction
        titre='Simplifiez la gestion des ressources humaines<br> et automatisez les formalités administratives'
        sousTitre="Automatisez les tâches chronophages et centralisez l'ensemble de vos données"
        v-bind:modules='["core-contrat", "core-document", "core-materiel"]'
        sens="gauche"
        balise="CORE RH pour centraliser les informations de vos salariés"
    ></presentation-fonction>

    <div class="marginBottom20 marginTop20"></div>


    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Le core RH vous permet de gagner en efficacité et de fluidifier la communication entre
              les différents services. Chaque collaborateur gagne du temps et sait ou trouver l'information.</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie">
            <h3 class="marginTop20">Collaborateurs</h3>
            <ul class="simplifieTexte marginTop20">
              <li>Une mise en place rapide et sans retard de leurs documents contractuels.</li>
              <li>Un accès à un portail collaborateur: les informations sont disponibles en temps réel.</li>
              <li>Partage de documents rapide, signature électronique et accès à leurs dossiers administratifs mais également,
                possibilité de suivre leurs objectifs en cours et d’effectuer une demande de formation.</li>
              <li>Gain de temps et d'autonomie</li>
            </ul>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">Des procédures administratives simplifiées grâce à l’automatisation et une centralisation
            des données entièrement personnalisable.<br>
            Vos ressources humaines gagnent du temps tout en uniformisant
              leur communication, et en sécurisant le respect de leurs obligations légales.</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Un suivi optimisé des objectifs et des formations pour accompagner encore mieux les collaborateurs.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Le logiciel Core RH<br>de quoi s'agit-il ?</h2>
            </div>
          </div>
          <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Le logiciel Core RH est un programme informatique qui rend la gestion ou l'administration du personnel plus aisée et efficace.
              Humatch vous invite à découvrir son SIRH.</h3>
          </div>
        </div>
          <div class="row marginBottom20 marginTop20">
            <div class="deuxColumn padding10 textExplication">
              <h2>Logiciel Core RH : une solution pour centraliser les données RH</h2>
              <p>
                Chaque collaborateur génère une quantité importante d'informations indispensables à la gestion des ressources humaines. Il
                s'agit par exemple des données personnelles (identité, date de naissance, adresse&hellip;), des données bancaires (RIB), des
                données d'assurance et de sécurité sociale. Des informations relatives aux qualifications des collaborateurs à leurs fonctions
                au sein de l'organisation et la nature de leur contrat sont également prises en compte. Peu importe la taille de votre personnel,
                dans une PME ou une multinationale, l'administration de ces données des collaborateurs n'est jamais aisée puisqu'elles sont
                dispersées sur plusieurs outils de gestion.
              </p>
              <p>
                Pour vous rendre la tâche plus facile, les<strong> logiciels SIRH</strong> ont pour r&ocirc;le de centraliser toutes ces
                informations afin de faciliter leur disponibilité, leur distribution et leur administration. Le logiciel Core RH que nous proposons
                favorise l'automatisation de nombreuses tâches de management au sein des entreprises et transforme les processus
                RH en un véritable levier de compétitivité.
              </p>
            </div>
            <div class="deuxColumn padding10 textExplication">
              <h2>Utilité et avantages d'un logiciel Core RH</h2>
              <p>
                Avec un logiciel Core RH, vous pouvez digitaliser toutes les fonctions qui interviennent dans la gestion du capital humain de
                votre entreprise. De la détection des talents en passant par le processus de recrutement des collaborateurs, jusqu'au suivi de
                carrière au sein de votre organisation, le processus devient plus fluide.
              </p>
              <h3>Les formalités administratives sont plus faciles</h3>
              <p>
                En permettant à chaque service d'avoir facilement accès aux informations essentielles, le logiciel de centralisation des
                données facilite <strong>l'automatisation des processus</strong>.
              <p>
                Un logiciel Core RH permet rend donc <strong>les interactions plus faciles entre les collaborateurs et les différentes
                équipes</strong>. Le personnel a la possibilité de lancer des requêtes directement sur la plateforme commune pour demander un
                document, des congés ou pour fournir un rapport d'activité. L'employé peut également gérer ses évaluations, ses entretiens
                et ses affectations directement à partir de l'interface du SIRH. La productivité en est meilleure, en plus du gain de temps
                réalisé sur la gestion.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Core',
  metaInfo: {
    title: "gestion des dossiers administratifs de vos collaborateurs",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/core-rh'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Le module de gestion administrative des RH offre une solution complète pour gérer efficacement les dossiers administratifs de vos collaborateurs'
      }
    ]
  },
  components: {PresentationFonction, Menu, Bottom, simplifier},
  data(){
    return {
      menu:"core"
    }
  }
}
</script>
