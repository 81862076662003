<template>
  <div>
    <div class="boxHautBackgroundColor"></div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Optimisez<br/> vos RH<br/> avec un SIRH sur<br/> mesure pour PME et ETI</h1>
              <span class="line-headline"></span>
            </div>
            <p>Libérez-vous des tâches administratives chronophages et concentrez-vous sur l'essentiel : <strong>le développement de vos talents</strong>.</p>
            <p>Notre solution SIRH sur mesure pour PME et ETI vous offre :</p>
            <p>- <strong>Des modèles de données pré-paramétrés</strong> par métier pour une mise en service rapide et simplifiée.</p>
            <p>- <strong>Des outils intuitifs de reprise de données</strong> pour un transfert facile de vos informations RH.</p>
            <p>- <strong>Une automatisation des tâches</strong> répétitives pour un gain de temps et d'efficacité.</p>
            <p>- <strong>Un budget maîtrisé</strong> grâce à une offre tout compris et transparente.</p>
            <p>- <strong>Automatisation basée sur l’IA: </strong> Création des fiches de fonctions, Aide à la décision,....</p>
            <div class="demandeDemo marginTop50">
              <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link>
            </div>
          </div>
          <div class="presentation-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fhumatch-accueil.svg?alt=media&token=15edc71a-a329-494d-84be-a963798dfc79" alt="logiciel SIRH">
          </div>
        </div>
      </div>
    </section>
    <presentation-fonction
        titre='Centralisez l’ensemble de vos données RH<br> et gérez les entretiens'
        sousTitre="Simplifiez la gestion des ressources humaines et automatisez les formalités administratives"
        v-bind:modules='["core-rh", "entretien"]'
        balise="Logiciel SIRH pour simplifier la gestion des RH"
        sens="droite"
    ></presentation-fonction>
    <presentation-fonction
      titre="Recrutez efficacement <br>et accompagnez vos nouveaux collaborateurs"
      sousTitre="Facilitez l'acquisition de talents et soignez l'intégration des nouveaux collaborateurs"
      v-bind:modules='["recrutement", "onboarding"]'
      sens="gauche"
      balise="Logiciel SIRH pour faciliter l'acquisition de talents"
    ></presentation-fonction>
    <presentation-fonction
        titre="Suivez les temps et les activités<br> de vos collaborateurs<br> et suivez leurs absences"
        sousTitre="Notre gestion des temps a été conçue pour simplifier le suivi d'activité des collaborateurs et de leurs heures de travail."
        v-bind:modules='["conges", "gta"]'
        sens="droite"
        balise="Suivez efficacement les temps avec le logiciel SIRH"
    ></presentation-fonction>
    <presentation-fonction
        titre="Développez les talents<br> de votre entreprise"
        sousTitre="La gestion des compétences et des talents, au service de votre entreprise"
        v-bind:modules='["gpec"]'
        sens="gauche"
        balise="gérer les talents de votre entreprise avec notre logiciel RH"
    ></presentation-fonction>
    <div class="marginBottom20 marginTop20"></div>
    <approuver></approuver>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Logiciel de gestion des ressources humaines<br>tout ce qu'il faut savoir</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
              <img src="./../assets/images/logiciel-rh.svg" alt="logiciel SIRH">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2 class="hauteur25">Fonctionnalités et atouts de notre SIRH</h2>
              <p>
                Ensemble de modules complémentaires, le SIRH Humatch s'occupe d'une multitude des processus
                de gestion d'une DRH. Sa mise en place permet à l'entreprise de simplifier et d'optimiser sa façon
                de gérer le personnel. Humatch propose actuellement l'une des solutions SIRH les plus complètes sur le marché.
            </p>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Qu'est-ce qu'un logiciel SIRH ?</h2>
              <p>
                Un système d'information de gestion de ressources humaines, communément appelé SIRH, est une plateforme qui embarque un
                ensemble de briques logicielles ou de modules. L'objectif d'un logiciel SIRH est d'<strong>automatiser et de suivre facilement
                certaines tâches</strong> liées à la gestion des ressources humaines. Cet outil informatique multifonctions permet à
                l'entreprise de s'occuper en un seul endroit de toutes les facettes de la gestion du personnel.
              </p>
              <p>
                Les logiciels qui sont présents dans un SIRH prennent en charge les fonctions essentielles suivantes :
              </p>
              <ul>
                <li>- la gestion des congés et des absences,</li>
                <li>- le recrutement,</li>
                <li>- la gestion des carrières,</li>
                <li>- la gestion de la formation et le développement des talents,</li>
                <li>- la gestion des compétences,</li>
                <li>- le pilotage RH,</li>
                <li>- la gestion des contrats et de la paie,</li>
                <li>- la gestion des temps et des activités&hellip;</li>
              </ul>
              <p>
                Un logiciel RH peut également être <strong>un outil de travail collaboratif</strong>. Il permet ainsi aux différents
                collaborateurs des services concernés par les processus RH de travailler ensemble tout en ayant des accès bien définis selon
                leur niveau d'accréditation.
              </p>
              <p>
                En plus des modules et fonctionnalités de base essentiels à tout outil RH, le système d'information de gestion des ressources
                humaines de Humatch propose un module permettant spécialement de gérer les intégrations de nouveaux collaborateurs (On/Off
                Boarding). Il est très pratique pour la création de contrats sur mesure, la préparation de l'arrivée des collaborateurs, le
                partage de documents et autres données&hellip;
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Pourquoi mettre en place un SIRH ?</h2>
              <p>
                La digitalisation a profondément changé les méthodes de travail et les enjeux auxquels font face les entreprises. La course à
                la compétitivité oblige également les sociétés à mettre en place des stratégies pour réduire les pertes de temps et
                accroître leur efficacité dans tous les domaines, surtout ceux ayant trait à la gestion administrative. Les services de gestion
                des ressources humaines ne sont pas en marge de ces changements. La fonction RH doit se doter des bons outils pour fluidifier ses
                processus et insuffler une dynamique positive à l'entreprise à travers une gestion dynamique des employés et collaborateurs.
                Dans ce contexte, <strong>la mise en place d'un logiciel SIRH performant</strong> s'avère indispensable.
              </p>
              <p>
                En dehors de la fluidification des process, les avantages qu'apporte l'implémentation d'un outil de gestion des ressources
                humaines sont nombreux :
              </p>
              <ul>
                <li>- meilleure priorisation des tâches,</li>
                <li>- accroissement de la productivité des salariés,</li>
                <li>- analyse efficace des performances internes des employés,</li>
                <li>- gestion des coûts liés au recrutement,</li>
                <li>- réduction des dépenses administratives.</li>
              </ul>
              <p>
                Le SIRH n'est pas seulement réservé aux grandes entreprises ayant de nombreux employés. Les PME et les ETI peuvent également
                s'en servir pour la gestion de leurs ressources humaines. Le logiciel que Humatch propose <strong>s'adapte à tous les types
                d'entreprises</strong>, quel que soit leur effectif.
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2>Comment réussir la digitalisation avec un logiciel SIRH simple et performant ?</h2>
              <p>
                La digitalisation RH avec une solution SIRH est un projet stratégique qui doit être bien mené. Il faut dans un premier temps
                choisir un logiciel RH adapté aux besoins de l'entreprise. Selon la taille de celle-ci, il n'est pas nécessaire de mettre en
                place tous les modules que comprend habituellement une application SIRH. En général, la plupart des PME se dotent uniquement des
                modules de gestion de paie, de gestion des temps et des activités et de gestion collaborative&hellip; 9 entreprises sur 10 optent
                également pour un outil de gestion administrative du personnel. De plus, il est important de vérifier la compatibilité de
                l'outil de gestion RH avec les autres solutions ou logiciels (Saas ou Cloud) que l'entreprise possède déjà. Il faut enfin
                prévoir un plan de formation des collaborateurs à l'utilisation du logiciel SIRH dès sa mise en service afin
                d'éviter les pertes de temps.
              </p>
              <p>
                Avec Humatch, vous avez accès à un logiciel RH complet et flexible intégrant tous les modules et toutes les fonctionnalités
                dont vous avez besoin pour une gestion optimale de vos ressources humaines. Nous pouvons vous aider à choisir l'offre la mieux
                adaptée à votre organisation. N'hésitez pas à nous contacter pour en savoir plus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>


import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import approuver from "@/components/approuver";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Accueil',
  metaInfo: {
    title: "SIRH sur mesure pour PME et ETI : Développement & Adaptation | Humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Notre logiciel SIRH offre une solution complète pour la gestion des ressources humaines, incluant la gestion des salaires, des absences et des performances. Améliorez la gestion de votre personnel avec notre solution facile à utiliser.'
      }
    ]
  },
  components: {PresentationFonction, Menu, Bottom, approuver, simplifier},
  data(){
    return {
      menu:""
    }
  }
}
</script>
