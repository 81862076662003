var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Menu'),_c('section',{staticClass:"presentation-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"presentation-text"},[_vm._m(0),_c('H2',[_vm._v("Humatch est disponible 24/24 x 7 avec un taux de disponibilité de 99,99%. ")]),_vm._m(1),_c('img',{staticClass:"marginTop20 security",attrs:{"src":require("./../assets/images/SOC_NonCPA.png"),"alt":"soc 2 type 2"}}),_c('img',{staticClass:"marginTop20 security",attrs:{"src":require("./../assets/images/GDPR.png"),"alt":"GDPR - RGPD"}}),_c('img',{staticClass:"iconSpecific marginTop20",attrs:{"src":require("./../assets/images/gaia-x.jpeg"),"alt":"gaia x"}})],1),_vm._m(2)])])]),_c('div',{staticClass:"marginBottom20 marginTop20"}),_c('section',{staticClass:"acquisition-of-talents-section boxSimplifie"},[_c('div',{staticClass:"container"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"row marginBottom20"},[_c('div',{staticClass:"planifier-button"},[_c('div',{staticClass:"demandeDemo marginBottom20"},[_c('router-link',{staticClass:"demo-request texteDemande",attrs:{"to":"/contact"}},[_vm._v("En savoir plus")])],1)])])])]),_c('div',{staticClass:"marginBottom20 marginTop20"}),_c('Bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Sécurité et conformité")]),_c('span',{staticClass:"line-headline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"sous-haut-page"},[_vm._v(" Humatch est disponible 24/24 x 7 avec un taux de disponibilité de 99,99%. La sécurité des données est important c'est pourquoi notre hébergeur est audité annuellement et certifié SOC 2 type 2 et membre de Gaia-X."),_c('br'),_c('br'),_vm._v(" Voici les éléments que nous avons mis en place pour la sécurité contre les cybers attacks et le respect du RGPD. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fonction-image marginTop100"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fsecurite-et-conformite.svg?alt=media&token=f125adc8-8973-44e6-b9a8-2d1b217b540c","alt":"Conformité et sécurité du SIRH"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"sous-titre marginTop100"},[_c('h2',[_vm._v("Quand vous utilisez humatch vous savez :")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row marginBottom20 marginTop20"},[_c('div',{staticClass:"sous-titre sousTitreSimplifie"},[_c('h3',[_vm._v(" - que la sécurité est notre objectif numéro un dans tous ce que nous faisons"),_c('br'),_vm._v(" - où vos données sont stockées et qu’elles sont disponibles quand vous en avez besoin."),_c('br'),_vm._v(" - que vos données ne sont pas utilisées pour de la publicité. Vous restez propriétaire de vos données."),_c('br'),_vm._v(" - que nous procédons à des tests de pénétration et vulnérabilité de manière régulière")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row padding80"},[_c('div',{staticClass:"troisColumn padding20"},[_c('img',{staticClass:"iconSimplifie",attrs:{"src":require("./../assets/images/shelf.svg")}}),_c('h3',{staticClass:"marginTop20"},[_vm._v("Cryptography et sécurité de l’utilisateur")]),_c('p',{staticClass:"simpl ifieTexte marginTop20"},[_vm._v(" Nous utilisons les standards en matière de cryptographie, cela inclut l’encryptage des disques. ")])]),_c('div',{staticClass:"troisColumn padding20"},[_c('img',{staticClass:"iconSimplifie",attrs:{"src":require("./../assets/images/infra.svg")}}),_c('h3',{staticClass:"marginTop20"},[_vm._v("Architecture redondante")]),_c('p',{staticClass:"simplifieTexte marginTop20"},[_vm._v("Nous avons mis en place une triple redondance de vos données et des backups automatiques de l’intégralité des données et ce dans la même région géographique.")])]),_c('div',{staticClass:"troisColumn padding20"},[_c('img',{staticClass:"iconSimplifie",attrs:{"src":require("./../assets/images/new.svg")}}),_c('h3',{staticClass:"marginTop20"},[_vm._v("Mise à jour de sécurité")]),_c('p',{staticClass:"simplifieTexte marginTop20"},[_vm._v("Dès qu’une mise à jour de sécurité est disponible nous mettons à jour nos infrastructures sans interruption de service.")])])])
}]

export { render, staticRenderFns }