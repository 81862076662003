<template>
  <footer>
    <footer class="footer">
      <div class="container">
        <div class="footer-wrap">
          <div class="footer-info">
            <div class="footer-adress">
              <img src="./../assets/images/logo.svg" alt="Humatch" />
              <p></p>
              <ul class="soc-link">
                <li>
                  <a target="_blank" href="https://www.facebook.com/humatchs"
                    ><img src="./../assets/images/Facebook.svg" alt="facebook"
                  /></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/humatch"
                    ><img src="./../assets/images/LinkedIn.svg" alt="linkedin"
                  /></a>
                </li>
              </ul>
              <p></p>
              <ul class="marginTop20">
                <li><strong>Lyon</strong></li>
                <li>
                  <a href="mailto:contact@humatch.fr">contact@humatch.fr</a>
                </li>
                <li><a href="tel:042786155363">Tel: 04 27 86 15 53</a></li>
                <li>63 Rue andré Bollier</li>
                <li>69007 LYON</li>
              </ul>
            </div>
          </div>
          <div class="footer-menu">
            <span class="footer-menu-title">Entreprise</span>
            <ul>
              <li>
                <router-link to="/societe">Société</router-link>
              </li>
              <li>
                <router-link to="/logiciel-rh-pourquoi">Pourquoi Humatch</router-link>
              </li>

              <li>
                <router-link to="/contact" class="texteDemande"
                  >Contact</router-link
                >
              </li>
            </ul>
          </div>
          <div class="footer-menu">
            <span class="footer-menu-title">Fonctionnalités</span>
            <ul>
              <li>
                <router-link to="/sirh/core-rh">Core RH</router-link>
              </li>
              <li>
                <router-link to="/sirh/logiciel-de-recrutement"
                  >Recrutement</router-link
                >
              </li>
              <li>
                <router-link to="/sirh/logiciel-integration-onboarding-employes"
                  >Intégration</router-link
                >
              </li>
              <li>
                <router-link to="/sirh/logiciel-gestion-entretien"
                  >Entretien annuel et professionnel</router-link
                >
              </li>
              <li>
                <router-link to="/sirh/logiciel-gpec"
                  >Gestion des compétences</router-link
                >
              </li>
              <li>
                <router-link to="/sirh/logiciel-gestion-des-temps"
                  >Gestion des temps</router-link
                >
              </li>
              <li>
                <router-link to="/sirh/logiciel-gestion-des-conges"
                  >Gestion des Congés</router-link
                >
              </li>
            </ul>
          </div>
          <div class="footer-menu">

            <span class="footer-menu-title">Ressources</span>
            <ul>
              <li>
                <a href="https://app.humatch.fr" target="_blank">Connectez vous</a>
              </li>
              <li>
                <router-link to="/actualite-digitalisez-vos-rh"
                  >Blog</router-link
                >
              </li>
              <li>
                <router-link to="/accompagnement"
                  >Accompagnement projet</router-link>
              </li>
              <li>
                <router-link to="/securite"
                >Sécurité et conformité</router-link>
              </li>
            </ul>
          </div>
          <div class="footer-menu">
            <span class="footer-menu-title"></span>
            <img
              src="./../assets/images/SOC_NonCPA.png"
              class="secBottom"
              alt="Soc 2 Type 2"
            /><br />
            <img
              src="./../assets/images/GDPR.png"
              class="footer-aide"
              alt="RGPD"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="footer-bottom__wrap">
          <div class="footer-bottom__wrap-item">
            <div class="footer-bottom">Copyright © 2022, Humatch</div>
          </div>
          <div class="footer-bottom__wrap-item">
            <div class="footer-bottom">
              <router-link to="/cgu">CGU</router-link>
            </div>
            <div class="footer-bottom">
              <router-link to="/protection-donnees"
                >Protection des données</router-link
              >
            </div>
            <div class="footer-bottom">
              <router-link to="/mentions-legales">Mentions légales</router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </footer>
</template>

<script>
export default {
  name: "Bottom",
};
</script>
