const firestoreConfig = {
    apiKey: "AIzaSyBGIVtomZdG4xKVlL9heCsCWjtwAyrYWfk",
    authDomain: "blog-humatch.firebaseapp.com",
    projectId: "blog-humatch",
    storageBucket: "blog-humatch.appspot.com",
    messagingSenderId: "460651215603",
    appId: "1:460651215603:web:3b74a21a0ede4a89209586",
    measurementId: "G-8XLL8JFXKE"
};

export default firestoreConfig;
