<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
              <div class="headline">
                <h1>Parlons RH <br>avec Humatch<br>le logiciel<br>qui digitalise<br>vos RH</h1>
                <span class="line-headline"></span>
              </div>
            <p class="soutien">
              Retrouvez toute notre actualité et des informations sur les sujets RH.
            </p>
          </div>
          <div class="presentation-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fparlons-rh.svg?alt=media&token=166e1093-1ef6-43f3-9a3f-fe4199d91d6c" alt="Parlons RH">
          </div>
        </div>
      </div>
    </section>

    <section class="blog">
      <div class="container">
        <div class="first-posts">
          <div class="item">
            <div class="block-default-image">
              <img v-bind:src="articlesList[0].image" v-bind:alt="articlesList[0].alt">
            </div>
            <div class="block-default-text margin-left-default">
              <h2>{{ articlesList[0].title }}</h2>
              <p v-html="articlesList[0].description"></p>
              <div class="section-link">
              <br/>
                <router-link :to="{ name: 'Article', params: { slug: articlesList[0].slug} }">En savoir plus</router-link>
              </div>
            </div>
          </div>
          <div class="item responsive-column-reverse">
            <div class="block-default-text">
              <h2>{{ articlesList[1].title }}</h2>
              <p v-html="articlesList[1].description"></p>
              <div class="section-link">
                <br/>
              <router-link :to="{ name: 'Article', params: { slug: articlesList[1].slug} }">En savoir plus</router-link>
              </div>
            </div>
            <div class="block-default-image margin-left-default">
              <img v-bind:alt="articlesList[1].alt" v-bind:src="articlesList[1].image">
            </div>
          </div>
        </div>
            <div class="blog-posts" >
              <div v-for="a in pageOfItems" v-bind:key="a.id" style="height: 520px">
                <router-link :to="{ name: 'Article', params: { slug: a.slug} }" class="item">
                  <div class="image imageArticle">
                    <img v-bind:src="a.image" v-bind:alt="a.alt">
                  </div>
                  <div class="text" >
                    <h2>{{ a.title }}</h2>
                    <div class="section-link listArticle">
                      <router-link :to="{ name: 'Article', params: { slug: a.slug} }">En savoir plus</router-link>
                    </div>
                  </div>
                </router-link>
            </div>
        </div>
        <div class="pagination">
          <jw-pagination :items="articlesList" @changePage="onChangePage" :styles="customStyles" :labels="customLabels"></jw-pagination>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Vue from "vue";
import db from '../shared/db';
import JwPagination from 'jw-vue-pagination';
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

Vue.component('jw-pagination', JwPagination);

const customStyles = {
  li: {
    display: 'inline-block',
    border: '2px purple'
  },
  a: {
    color: 'grey'
  }
};

const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>'
};



export default {
  name: 'Actualites',
  components: {Menu, Bottom },
  metaInfo: {
    title: "Parlons RH et SIRH",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/actualite-digitalisez-vos-rh'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "Notre application est en constante évolution, nous partageons avec vous des informations sur les RH et des nouveautés du secteur."
      }
    ]
  },
  data(){
    return {
      articlesList:[],
      article:{},
      menu:"actu",
      pageOfItems: [],
      customStyles, customLabels
    }
  },
  methods:{
    async getArticles() {
     db.getArticles().then((snapshot) => {
       this.articlesList = snapshot
     })
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  created() {
    this.getArticles(db);
  }
}
</script>

<style scoped>

ul{
  list-style-image: url("./../assets/images/purple-point.svg");
  padding-left: 18px;
}

</style>

