<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container" id="app">
        <div class="row">
          <div class="presentation-text">
            <h1>Contactez nous pour digitalisez vos RH</h1>
            <p>Une question sur notre solution RH ? Renseignez ce formulaire, exprimez vos besoins et nous vous
              répondrons au plus vite.</p>
          </div>
          <div class="tarif-top-form panel">
            <form v-on:submit.prevent="createContact">
              <div class="alert" v-if="message.length>0">{{ message }}</div>
              <div class="form-header">
                <br>
                <p>Contactez nous</p>
                <br>
              </div>
              <div class="form-inputs">
                <div class="input-container">
                  <label>Nom *</label>
                  <input type="text" v-model="contact.lastName" required>
                </div>
                <div class="input-container">
                  <label>Prénom *</label><br>
                  <input type="text" v-model="contact.firstName" required>
                </div>
                <div class="input-container">
                  <label>Email *</label><br>
                  <input type="email" v-model="contact.email" required>
                </div>
                <div class="input-container">
                  <label>Téléphone *</label><br>
                  <input type="text" v-model="contact.phone" required>
                </div>
                <div class="input-container">
                  <label>Message</label>
                  <textarea rows="5" cols="10" v-model="contact.message" ></textarea>
                </div>
                <div class="submit-container">
                    <input type="submit" value="ENVOYER">
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <Bottom></Bottom>
  </div>
</template>

<script>
import db from '../shared/db';
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Contact',
  metaInfo: {
    title: "Contactez nous",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/contact'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Contactez nous'
      }
    ]
  },
  components: {Menu, Bottom},
  data() {
    return {
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        menu: 'contact',
      }
    }
  },
  methods: {
    createContact() {
      db.createContact(this.contact);
      this.contact = {};
      this.message = "Votre message a été envoyé"
    }
  },
  created() {
    this.message = ""
  }
}
</script>
