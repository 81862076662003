import {initializeApp} from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, query, where, orderBy } from 'firebase/firestore/lite';

import fsConfig from "@/shared/config";

const config =  fsConfig;

const app = initializeApp(config);
const db = getFirestore(app);

export default {
    async getArticles() {
        let articles = [];
        const art = collection(db, "articles");
        const q = query(art, orderBy("id", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            articles.push(doc.data())
        });
        return articles;
    },
    async getArticle(slug) {
        let article = [];
        const articles = collection(db, "articles");
        const q = query(articles, where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            article.push(doc.data());
        });
        return article;
    },
    async getFonction(module) {
        let fonction = [];
        const fonctions = collection(db, "fonctionnalites");
        const q = query(fonctions, where("module", "==", module));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fonction.push(doc.data());
        });
        return fonction;
    },
    async getListe(module) {
        let liste = [];
        const fonctions = collection(db, "fonctions");
        const q = query(fonctions, where("module", "==", module));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            liste.push(doc.data());
        });
        return liste;
    },
    async getNouveautes() {
        let nouveautes = [];
        const art = collection(db, "nouveautes");
        const q = query(art, orderBy("id", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            nouveautes.push(doc.data())
        });
        return nouveautes;
    },
    async getNouveaute(slug) {
        let nouveaute = [];
        const nouveautes = collection(db, "nouveautes");
        const q = query(nouveautes, where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            nouveaute.push(doc.data());
        });
        return nouveaute;
    },
    readContact(){
        return getDocs(db, "contacts");
    },
    async createContact(contact){
        await addDoc(collection(db, "contacts"), { contact });
    }
}
