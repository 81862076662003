<template>
  <div>
    <Menu></Menu>
    <section class="top-section">
      <div class="container">
        <div class="top-section-text">
          <h1>CONDITIONS GÉNÉRALES D’UTILISATION</h1>
          <p class="textDescription">Dernière mise à jour: le 1 juillet 2021</p>
        </div>
      </div>
    </section>
    <section class="easy-to-create-section">
      <div class="container textDescription">
        <h4>Article 1 - Acceptation</h4>

        <p>Lorsque vous accédez aux Services en ligne de l’application Humatch (ci-après dénommés les «
          Services »), au site web Humatch (ci-après dénommé le « Site ») quelle que soit la plateforme
          depuis laquelle elle a été téléchargée, ainsi qu’à tout logiciel fourni par Humatch, ou en son
          nom, relatif ou lié auxdits Services, vous certifiez avoir lu et compris la Politique de cookies
          (partie II. ci-dessous des présentes CGUV) et la Politique de confidentialité (partie III.
          ci-dessous des présentes CGUV) et vous convenez des modalités des présentes Conditions
          d’utilisation et de vente des Services (ci-après dénommées les « CGUVV ») avec la société
          suivante : HUMATCH, 63 rue André Bollier 69003 Lyon, RCS LYON n°
          852 399 724 (ci-après dénommée « Humatch »).</p>

        <p>Tout accès au Site et/ou à l’Application et/ou utilisation du Site et/ou de l’Application,
          suppose l’acceptation et le respect de l’ensemble des termes des présentes conditions et leur
          acceptation inconditionnelle. Elles constituent un contrat entre Humatch et l’internaute qui
          accède au Site ou à l’Application (ci-après dénommé l’« Utilisateur ») en s’étant ou non
          préalablement inscrit et en ayant créé ou non un compte sur le Site ou l’Application (ci-après
          dénommé le « Compte »). Les Utilisateurs peuvent être de trois catégories : d’une part, les
          Entreprises qui souscrivent aux Services (ci-après dénommés les « Entreprises Utilisatrices »),
          puis , les Salariés des Entreprises Utilisatrices qui utilisent les Services depuis le Compte de
          leur Entreprise Utilisatrice (ci-après dénommées les « Salariés ») et d’autre part les Candidats
          libres (ci après dénommés les « Candidats »).</p>

        <p>Le présent contrat est conclu pour une durée indéterminée à compter de l’utilisation du Site ou
          de l’Application par l’Utilisateur.</p>

        <p>Humatch se réserve la possibilité de faire évoluer à tout moment le Site et l’Application ainsi
          que les présentes conditions générales d’utilisation et de vente (CGUV), ou encore de compléter
          celles-ci par de nouvelles conditions contractuelles complémentaires. C’est pourquoi, il est
          conseillé aux Utilisateurs de consulter régulièrement les conditions générales d’utilisation et
          de vente, afin de se référer à la dernière version en vigueur.</p>

        <p>Dans l’hypothèse où l’Utilisateur ne souhaite pas accepter tout ou partie des présentes
          conditions générales, la Politique de cookies ou la Politique de confidentialité, il lui est
          demandé de renoncer à tout usage du Site ou de l’Application.</p>


        <h4>Article 2 – Objet</h4>

        <p>Les présentes conditions générales ont pour objet de définir les modalités et conditions
          d’utilisation des services proposés sur le Site et/ou l’Application (ci-après : les « Services
          »), ainsi que de définir les droits et obligations des parties dans ce cadre. Elles sont
          notamment accessibles et imprimables à tout moment par un lien direct en bas de la page
          d’accueil du site.</p>


        <h4>Article 3 - Modalités générales d’utilisation des Services en ligne et/ou mobile (Site ou
          Application) proposés par Humatch</h4>

        <p>Les Services constituent un logiciel web en mode SAAS permettant aux Utilisateurs des
          Applications mobiles et/ou des Sites Web de Humatch, d’accéder à certains Services.</p>

        <p>Sous réserve que vous respectiez les présentes Conditions, Humatch vous accorde, en tant
          qu’Utilisateur, une licence limitée, non exclusive, sans droit de sous-licence, révocable et non
          cessible :</p>
        <p>- d’accès et d’utilisation au Site et à l’Application sur votre dispositif personnel pour ce qui
          a trait uniquement à votre utilisation des Services ;</p>
        <p>- et d’accès et d’utilisation de tout contenu, information et matériel y afférent pouvant être
          mis à votre disposition dans le cadre des Services.</p>

        <h4>Article 4 – Contenu des Utilisateurs</h4>

        <p>Humatch décline toute responsabilité vis-à-vis du contenu publié par les Utilisateurs, y compris
          les documents, informations, opinions, conseils, ou déclarations le constituant.
          Les Utilisateurs ne doivent publier aucun contenu utilisateur illégal, frauduleux,
          discriminatoire, menaçant, abusif, calomnieux, diffamatoire, obscène ou autrement répréhensible,
          comprenant des insultes à caractère sexuel, ethnique, racial ou tout autre propos
          discriminatoire ou ne comprenant rien de pertinent ou de constructif.</p>


        <h4>Article 5 – Règles d’utilisation du Site et de l’Application</h4>

        <p>Vous vous engagez à ne pas accéder (ou chercher à accéder) au Site ou à l’Application d’une
          autre façon que par l’interface fournie par Humatch.</p>

        <p>Vous vous engagez à ne vous livrer à aucune activité de nature à entraver ou à perturber le
          fonctionnement du Site ou de l’Application.</p>

        <p>Vous vous engagez à ne pas publier sur ou via le Site ou l’Application (sous quelque autre forme
          que ce soit) de Codes malveillants.</p>

        <h4>Article 6 – Clause de non-garantie</h4>


        <p>Humatch décline toute responsabilité en cas de suppression, de défaut de stockage, d’erreur.</p>

        <p>Humatch décline également toute responsabilité en cas de dysfonctionnement technique du Site ou
          de l’Application.</p>


        <h4>Article 7 – Limitation de responsabilité</h4>

        <p>L'Utilisateur utilise le Site et l’Application et les résultats obtenus par la mise en œuvre du
          Site et de l’Application sous sa responsabilité exclusive, sans recours possible contre Humatch.
          Notamment, la responsabilité de Humatch ne saurait être engagée à raison d'erreurs, quelle qu'en
          soit la cause, dans les résultats obtenus, qu'il appartient à l'Utilisateur de vérifier. Humatch
          ne saurait de même être tenu responsable de préjudices consécutifs aux dysfonctionnements et à
          l'indisponibilité du Site et de l’Application et/ou des Services ni de la destruction
          accidentelle des données qui sont enregistrées dans le Site ou l’Application. Toutes pertes des
          données, de documents, de contrats, de chiffres d’affaires, de clientèles, de profits et plus
          généralement, tout préjudice immatériel quelle qu’en soit la nature ou la cause, ne pourra lieu
          à réparation, notamment financière, ni au paiement de dommages-intérêts de la part de Humatch.
          Humatch s’engage à assurer la maintenance et le suivi du Site et de l’Application afin de
          permettre à l'Utilisateur d’utiliser le Site ou l’Application conformément à sa destination.</p>

        <p>En cas de condamnation de Humatch pour quelque cause que ce soit qui ne serait pas exclue par le
          présent Contrat, il est toutefois convenu que sa responsabilité serait limitée d'un commun
          accord à la somme globale et fixe de deux cent (200€) euros, et ce qu’elle que soit la nature de
          cette responsabilité, contractuelle, délictuelle, quasi-délictuelle ou pénale.</p>


        <h4>Article 8 – Demandes d’informations ou réclamations</h4>
        <p>Pour toute autre question ou réclamation concernant le Site ou l’Application, merci de nous
          adresser un mail à l’adresse suivante : contact@humatch.fr</p>

        <h4>Article 9– Droit applicable et résolution des litiges</h4>

        <p>Le Site et l’Application ont été conçus en France. Leur contenu est régi par le Droit Français,
          et tout litige éventuel s’y rapportant sera soumis à la compétence des tribunaux français.</p>

      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Cgu',
  metaInfo: {
    title: "Conditions générales d'utilisation - humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/cgu'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Conditions générales d\'utilisation - humatch'
      }
    ]
  },
  components: {Menu, Bottom},
  data(){
    return {
      articlesList:[],
      article:{},
      menu:"cgu"
    }
  },
}
</script>
