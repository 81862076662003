<template>
<div>
  <Menu></Menu>
  <section class="presentation-section">
    <div class="container">
      <div class="row">
        <div class="presentation-text">
          <div class="headline">
            <h1>Sécurité et conformité</h1>
            <span class="line-headline"></span>
          </div>
          <H2>Humatch est disponible 24/24 x 7 avec un taux de disponibilité de 99,99%. </H2>
          <h3 class="sous-haut-page">
            Humatch est disponible 24/24 x 7 avec un taux de disponibilité de 99,99%.
            La sécurité des données est important c'est pourquoi notre hébergeur est audité annuellement et certifié SOC 2 type 2 et
            membre de Gaia-X.<br><br>
            Voici les éléments que nous avons mis en place pour la sécurité contre les cybers attacks et le respect du RGPD.
          </h3>
          <img src="./../assets/images/SOC_NonCPA.png" class="marginTop20 security" alt="soc 2 type 2">
          <img src="./../assets/images/GDPR.png" class="marginTop20 security" alt="GDPR - RGPD">
          <img src="./../assets/images/gaia-x.jpeg" class="iconSpecific marginTop20" alt="gaia x">
        </div>
        <div class="fonction-image marginTop100">
          <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fsecurite-et-conformite.svg?alt=media&token=f125adc8-8973-44e6-b9a8-2d1b217b540c" alt="Conformité et sécurité du SIRH">
        </div>
      </div>
    </div>
  </section>
  <div class="marginBottom20 marginTop20"></div>
  <section class="acquisition-of-talents-section boxSimplifie">
    <div class="container">
      <div class="row">
        <div class="sous-titre marginTop100">
          <h2>Quand vous utilisez humatch vous savez :</h2>
        </div>
      </div>
      <div class="row marginBottom20 marginTop20">
        <div class="sous-titre sousTitreSimplifie">
          <h3>
            - que la sécurité est notre objectif numéro un dans tous ce que nous faisons<br>
            - où vos données sont stockées et qu’elles sont disponibles quand vous en avez besoin.<br>
            - que vos données ne sont pas utilisées pour de la publicité. Vous restez propriétaire de vos données.<br>
            - que nous procédons à des tests de pénétration et vulnérabilité de manière régulière</h3>
        </div>
      </div>
      <div class="row padding80">
        <div class="troisColumn padding20">
          <img src="./../assets/images/shelf.svg" class="iconSimplifie">
          <h3 class="marginTop20">Cryptography et sécurité de l’utilisateur</h3>
          <p class="simpl ifieTexte marginTop20">
            Nous utilisons les standards en matière de cryptographie, cela inclut l’encryptage des disques.
          </p>
        </div>
        <div class="troisColumn padding20">
          <img src="./../assets/images/infra.svg" class="iconSimplifie">
          <h3 class="marginTop20">Architecture redondante</h3>
          <p class="simplifieTexte marginTop20">Nous avons mis en place une triple redondance de vos données et des backups automatiques de l’intégralité des données et ce dans la même région géographique.</p>
        </div>
        <div class="troisColumn padding20">
          <img src="./../assets/images/new.svg" class="iconSimplifie">
          <h3 class="marginTop20">Mise à jour de sécurité</h3>
          <p class="simplifieTexte marginTop20">Dès qu’une mise à jour de sécurité est disponible nous mettons à jour nos infrastructures sans interruption de service.</p>
        </div>
      </div>
      <div class="row marginBottom20">
        <div class="planifier-button">
          <div class="demandeDemo marginBottom20" >
            <router-link to='/contact' class="demo-request texteDemande">En savoir plus</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="marginBottom20 marginTop20"></div>


  <Bottom></Bottom>
</div>
</template>

<script>
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: "Securite",
  metaInfo: {
    title: "Conformité et sécurité - SIRH",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/securite'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Humatch s\'engage sur un taux de disponibilité de 99,99% avec une triple redondance des infrastructures.'
      }
    ]
  },
  components: { Menu, Bottom},
  data(){
    return {
      menu:"securite"
    }
  }
}
</script>

<style scoped>

</style>
