<template>
  <div class="row">
    <div class="elementWrapper" v-bind:class="{ elementSelected: checkVoir(numero, selected)}" v-on:click="getVoir(index)">
      <div class="iconWrapper" v-bind:style="{background: (this.fonction[0])?this.fonction[0].couleur:''}">
        <img v-bind:src="getIconUrl((fonction[0])?fonction[0].icon:0)" class="icon">
      </div>
      <div class="titreFonction">
        <div style="margin-top: 8px" v-html="(fonction[0])?fonction[0].titre:''"></div>
      </div>
      <div class="row" v-show="voir">
        <div v-html="(fonction[0])?fonction[0].descriptionCourte:''" class="descriptionCourte">
        </div>
      </div>
      <div class="row" v-show="voir">
        <router-link v-bind:to="(fonction[0])?fonction[0].lien:''" class="lienDescription">En savoir plus
            <img class="flecheDroite" src="./../assets/images/Arrow-right.png">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import db from '../shared/db';

export default {
  name: "detail-function",
  props: ['module',  'numero', 'selected'],
  data() {
    return {
      fonction: [],
      lienImage: "",
      voir: false,
      index:""
    }
  },
  methods: {
    async getFonction() {
      db.getFonction(this.module).then((snapshot) => {
        this.fonction = snapshot
        this.voir = this.fonction[0].voir
        if(this.voir){
          this.getVoir()
        }
      })
    },
    getIconUrl(lien) {
      if(lien !==0){
        var images = require.context('./../assets/images/')
        return images('./' + lien)
      }
    },
    getVoir(){
      var images = require.context('./../assets/images/')
      this.lienImage = images('./' + this.fonction[0].image)
      this.$emit('chargeImage', [this.lienImage, this.numero])
    },
    checkVoir(s, n){
      if(s==n){
        this.voir = true;
       return true
      }
      this.voir = false;
      return false
    }
  },
  created() {
    this.getFonction(db);
  }
}
</script>

<style scoped>

.elementWrapper {
  padding: 32px;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.elementSelected {
  /* Light/Violet */
  background: #F4F5FE;
  border-radius: 16px;
  margin: 0px 0px;
}

.titreFonction {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.descriptionCourte {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
  width: 80%;
}

.iconWrapper {
  display: flex;
  float: left;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
}

.icon {
  position: static;
  width: 28px;
  height: 28px;
  left: 6px;
  top: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.lienDescription {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  margin-left:65px
}

.flecheDroite {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-left: 5px;
}


</style>
