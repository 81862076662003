<template>
  <div>
    <Menu></Menu>
    <section class="top-section">
      <div class="container">
        <div class="top-section-text">
          <h1>PROTECTION DES DONNÉES</h1>
          <p class="textDescription">Charte de Protection des Données Personnelles en matière de ressources humaines</p>
        </div>
      </div>
    </section>
    <section class="easy-to-create-section">
      <div class="container textDescription">
        <h5>Notre Engagement</h5>

        <p>En votre qualité de client ou de candidat de l’une des applications de la société Humatch, des conditions de travail respectueuses et sûres doivent vous être assurées.</p>

        <p>La confiance que vous portez à Humatch est essentielle.</p>

        <p>Nous savons que la protection de la vie privée fait partie de vos attentes. C'est la raison pour
          laquelle nous avons établi et mis en place la présente Charte de Protection des Données
          Personnelles en matière de Ressources Humaines (ci-après la "Charte") qui décrit la façon dont
          Humatch utilise vos Données Personnelles conformément aux 7 Principes de Protection des Données
          de Humatch (ci-après les "7 Principes de Protection des Données Humatch") qui constituent les
          principes clés de Humatch et les modalités suivant lesquelles s'appliquent donc à l'ensemble des
          applications Humatch dans le monde entier.</p>

        <p>Acceptation de la présente Charte de Protection des Données Personnelles en matière de ressources
          humaines</p>

        <p>La présente Charte doit être lue attentivement afin de vous permettre de disposer des
          informations sur la nature des Données Personnelles que Humatch détient sur ses salariés et sur
          les candidats, et la façon dont elle les utilise. Par "Données Personnelles" (ci-après les
          "DP"), nous entendons toute information collectée et enregistrée dans un format qui permet de
          vous identifier personnellement, soit directement (ex : votre nom), soit indirectement (ex:
          numéro de téléphone) en qualité de personne physique.</p>

        <p>En acceptant de postuler sur un site de la société Humatch, vous acceptez expressément la
          présente Charte qui est intégrée au dit contrat ou qui peut, dans certains pays, être intégrée
          au Règlement Intérieur de votre Société. Vous reconnaissez et acceptez que la présente Charte ne
          vous concède aucun autre droit que ceux prévus par la loi locale.</p>

        <p>Toutefois, lorsque la loi locale le requiert, la présente Charte pourra être soumise à votre
          accord préalable.</p>

        <p>Les 7 Principes de Protection des Données de Humatch</p>

        <p>Vous trouverez ci-après les 7 Principes de Protection des Données Humatch qui s'appliquent à
          l'ensemble des applications Humatch, dans le monde entier.</p>

        <p>1. Transparence : Lors de la collecte et du traitement de vos DP, nous vous communiquerons toutes
          informations et vous informerons de la finalité du traitement et des destinataires des
          données.</p>

        <p>2. Légitimité : Nous collecterons et traiterons vos DP uniquement aux fins qui sont décrites dans
          la présente Charte.</p>

        <p>3. Pertinence et exactitude : Nous ne collecterons que les DP qui sont nécessaires au traitement
          des données. Nous prendrons toutes les mesures raisonnables pour vous garantir que les DP que
          nous conservons sont exactes et à jour.</p>

        <p>4. Conservation : Nous conserverons vos DP pendant la période nécessaire aux fins du traitement
          des données, conformément aux dispositions de la présente Charte et aux exigences prévues par la
          loi locale.</p>

        <p>5. Accès et rectification : Vous avez la possibilité d'accéder, de modifier, de corriger ou de
          supprimer vos DP. Les coordonnées du service à contacter sont indiquées ci-dessous.</p>

        <p>6. Confidentialité et sécurité : Nous mettrons en place des mesures techniques et
          organisationnelles raisonnables pour protéger vos DP contre l'altération ou la perte
          accidentelle ou illicite ou l'utilisation, la divulgation ou l'accès non autorisé(e).</p>

        <p>7. Partage et transfert: Nous pouvons partager vos DP au sein du Groupe Humatch ou avec des tiers
          (tels que les partenaires commerciaux et les prestataires de services) aux fins exposées dans la
          présente Charte . Nous prendrons des mesures appropriées pour garantir la sécurité du partage et
          du transfert.</p>

        <p>Pour toutes questions concernant ces 7 Principes de Protection des Données Humatch, veuillez
          contacter le service dont les coordonnées figurent ci-dessous.</p>

        <h5>Champ d’application</h5>

        <p>La présente Charte s'applique à :</p>

        <p>tous les clients, salariés ou candidats utilisant les applications Humatch qui travaillent pour
          une entité cliente.</p>

        <p>les candidats à un poste au travers d’une application humatch.</p>

        <p>ci-après, dénommés, "vous".</p>

        <p>Quelles sont les Données Personnelles collectées ?</p>

        <p>Dans la mesure où la loi locale le permet, nous collectons, traitons et conservons des DP vous
          concernant, telles que :</p>

        <p>Données d'identification : nom de naissance, nom d'époux (-se), prénom, sexe, date et lieu de
          naissance, nationalité, coordonnées (adresse, numéro de téléphone personnel, adresse
          électronique et nom et numéro de téléphone de la personne à contacter en cas d'urgence), numéro
          de passeport (pour les salariés effectuant des déplacements à l'international), numéro de permis
          de conduire (si cette information est pertinente dans le cadre du poste), photos ;</p>

        <p>Situation familiale : état civil, nom, prénom et date de naissance de votre époux (-se) ou
          partenaire, nom, prénom et date de naissance des enfants, informations en matière d'assurance et
          de retraite ;</p>

        <p>Formation et carrière : diplômes et certificats de formation, langues étrangères parlées,
          curriculum vitae (détaillant votre expérience professionnelle et le cas échéant, la formation
          continue), situation en termes de mobilité et gestion du plan de carrière, suivi de l'évaluation
          annuelle des performances ;</p>

        <p>Vie professionnelle : contrat à durée déterminée, contrat à durée indéterminée, emploi à temps
          partiel ou à temps plein, date d'embauche, date de résiliation du contrat, intitulé du poste,
          informations sur le poste, numéro de téléphone et adresse électronique, description du poste</p>

        <p>Toutes autres DP supplémentaires dont nous avons besoin pour gérer la relation de travail ou que
          vous nous avez communiquées lorsque vous avez déposé une candidature à un poste au sein d’une
          application Humatch (ex : curriculum vitae ou lettre de motivation, rapport d'évaluation de
          cabinets de recrutement externes ainsi que des anciens responsables).</p>

        <p>Quelles sont les finalités ?</p>

        <p>Vos DP sont collectées, traitées et conservées par Humatch, y compris, notamment, aux fins
          suivantes :</p>

        <p>Pour vérifier les qualifications :</p>
        <p>- Les DP des collaborateurs sont collectées soit directement soit indirectement pour permettre à
          Humatch d'évaluer l'aptitude du candidat à l'exécution de sa mission (ex : vérification de ses
          références et qualifications).</p>

        <p>Gestion des performances et des talents :</p>
        <p>- Pour faciliter la gestion des performances et le développement de carrière des salariés,
          notamment dans le cadre des évaluations annuelles des performances, de la revue annuelle des
          salaires et, le cas échéant, des sanctions disciplinaires conformément au droit local.</p>

        <p>Gestion générale :</p>
        <p>- Organigrammes ;</p>
        <p> - Gestion des dossiers des salariés ;</p>

        <p>Gestion des ressources :</p>
        <p> - Pour la répartition et l'entretien des ressources. Ex : gestion des droits d'accès des
          salariés aux salles de conférence, aux locaux, au système informatique et à la base de
          données.</p>


        <p>Divulgation aux autorités :</p>
        <p>- Lorsque les autorités judiciaires et/ou les forces de police le demandent dans le cadre d'une
          enquête judiciaire ;</p>
        <p>- Sous réserve des dispositions légales locales, lorsque Humatch peut utiliser vos DP pour
          protéger ses droits ou étayer toute réclamation, défense ou déclaration dans une affaire ou
          devant les autorités juridictionnelles et/ou administratives, un tribunal arbitral ou un
          médiateur, dans le cadre d'actions / d'enquêtes disciplinaires ou d'un audit ou d'une
          investigation interne ou externe.</p>

        <p>Partage de vos données personnelles</p>

        <p>Humatch peut partager vos DP avec tous les clients, tels que :</p>

        <p>- les prestataires de services impliqués dans les différents traitements des données en matière
          de Ressources Humaines, tels que décrits ci-dessus ;</p>
        <p>- les autorités locales ou autres ;</p>

        <p>Humatch partagera vos DP uniquement si cela est nécessaire aux fins susmentionnées. Humatch
          demande aux destinataires de préserver la confidentialité de vos DP et de ne les utiliser que
          dans le cadre de la mission qu’ils réalisent pour Humatch.</p>

        <p>Humatch se réserve le droit de divulguer vos DP aux autorités publiques dans le cadre de demandes
          officielles, à des fins de sécurité nationale ou lorsque la loi le requiert.</p>

        <p>Sécurité des données</p>

        <p>Humatch prend des mesures techniques et organisationnelles appropriées conformément aux
          dispositions légales applicables, pour protéger vos DP contre la destruction accidentelle ou
          illicite, la perte ou l'altération accidentelle, ou encore la divulgation ou l'accès non
          autorisé(e). A cet effet, des mesures techniques (telles que des pare-feux) et des mesures
          organisationnelles (telles qu'un système d'identifiant / de mot de passe, des moyens de
          protection physique, etc.) ont été mis en place.</p>

        <p>Conservation des données</p>
        <p>Nous stockerons vos DP uniquement pendant la période nécessaire aux fins exposées dans la
          présente Charte, ou conformément à ce qui est prévu par la loi applicable.</p>

        <p>Accès et modification</p>

        <p>Vous avez le droit d'accéder à vos DP collectées par Humatch et de les modifier, sous réserve des
          dispositions légales applicables.</p>

        <p>A cette fin, vous pouvez exercer vos droits en contactant notre département Protection Données
          Personnelles à Humatch: contact@humatch.fr</p>

        <p>Mises à jour</p>

        <p>La présente Charte est susceptible d’évolutions ou de modifications.</p>
        <p>Par conséquent, nous vous invitons à prendre régulièrement connaissance de la présente Charte
          telle que publiée sur nos différents sites internet.</p>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Cgu',
  metaInfo: {
    title: "Protection des données - Humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/protection-donnees'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Politique de protection des données - humatch'
      }
    ]
  },
  components: {Menu, Bottom},
  data(){
    return {
      menu:"protection"
    }
  }
}
</script>
