var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Menu'),_c('section',{staticClass:"presentation-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"presentation-text"},[_vm._m(0),_c('H2',[_vm._v("Notre objectif : Vous accompagner tout au long de votre projet SIRH pour garantir sa réussite et vous permettre de tirer pleinement parti de notre solution.")]),_c('br'),_c('br'),_c('h2',[_vm._v("Notre vision : Un projet réussi ne se résume pas à la simple digitalisation de vos processus RH. Il s'agit avant tout d'une transformation positive pour vos équipes, qui s'approprient la solution en toute sérénité et découvrent de nouveaux modes de travail plus efficients.")]),_vm._m(1)],1),_vm._m(2)])])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('Bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("L'accompagnement de votre projet SIRH : Notre engagement pour votre réussiteH")]),_c('span',{staticClass:"line-headline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"sous-haut-page"},[_vm._v(" Notre méthodologie:"),_c('br'),_vm._v(" 1. Accompagnement au diagnostic de l'organisation:"),_c('br'),_vm._v(" - Analyse approfondie de vos besoins et de vos processus RH existants."),_c('br'),_vm._v(" - Identification des points d'amélioration et des objectifs à atteindre."),_c('br'),_vm._v(" - Élaboration d'une feuille de route personnalisée pour votre projet."),_c('br'),_c('br'),_vm._v(" 2. Proposition de fonctionnalités avec adaptation ou création des modules nécessaires:"),_c('br'),_vm._v(" - Mise à disposition de notre large gamme de modules SIRH standard."),_c('br'),_vm._v(" - Adaptation et personnalisation des modules pour répondre à vos besoins spécifiques."),_c('br'),_vm._v(" - Création de modules sur-mesure pour combler d'éventuels manques fonctionnels."),_c('br'),_c('br'),_vm._v(" 3 - Notre engagement :"),_c('br'),_vm._v(" - Une équipe d'experts dédiée à votre projet, à l'écoute de vos besoins et de vos attentes."),_c('br'),_vm._v(" - Une communication transparente et régulière tout au long du projet."),_c('br'),_vm._v(" - Un suivi rigoureux et des points d'étape réguliers pour garantir l'avancement du projet."),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fonction-image marginTop100"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Faccompagnement.svg?alt=media&token=5ea89a9e-f148-4eba-95fa-da0914b93854","alt":"Intégration et rdd de votre SIRH"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"history-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row responsive-column-reverse"},[_c('div',{staticClass:"block-default-image"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Foutil-simple-et-intuitif.avif?alt=media&token=a8920afd-7513-493c-886a-01abe81f878d","alt":"application simple et intuitive"}})]),_c('div',{staticClass:"block-default-text propos-block-default-text"},[_c('h2',[_vm._v("Outil simple et intuitif")]),_c('p',[_vm._v(" Nous avons développé un outil simple et intuitif, accessible à tous niveau d’utilisation numérique, où vous pouvez vous-même, intégrer facilement vos données. ")]),_c('p',[_vm._v(" Cependant, un petit coup de main peut s’avérer utile, en cas de manque de ressources SI ou fonctionnel en interne, ou simplement parce que changer de méthode n'est pas chose aisée, être accompagné est rassurant. ")]),_c('p',[_vm._v("Pour beaucoup le changement est stressant, c’est pourquoi nous savons qu’il est primordial de vous proposer un service vous permettant d’aborder le déploiement sereinement.")]),_c('p',[_vm._v("La clé du succès de la prise en main d’un nouvel outil c’est :")]),_c('ul',[_c('li',[_vm._v("Connaitre les étapes de mises en œuvre et ce qu’elles impliquent comme action")]),_c('li',[_vm._v("Accéder à ses données simplement")]),_c('li',[_vm._v("Un cursus d’apprentissage rapide")]),_c('li',[_vm._v("Se sentir accompagner, savoir à qui demander de l’aide")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"history-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row responsive-column-reverse"},[_c('div',{staticClass:"block-default-text propos-block-default-text"},[_c('h2',[_vm._v(" Des experts à votre écoute pour vous accompagner ")]),_c('p',[_vm._v(" C’est pour cela que nous vous proposons des prestations de déploiement vous permettant de vous reposer sur notre expertise pour une mise en place fluide et rapide. ")]),_c('ul',[_c('li',[_vm._v("Pilotage de projet")]),_c('li',[_vm._v("Définition du périmètre")]),_c('li',[_vm._v("Ateliers de paramétrage")]),_c('li',[_vm._v("Ateliers de reprise de données")]),_c('li',[_vm._v("Paramétrage outil")]),_c('li',[_vm._v("Intégration des données")]),_c('li',[_vm._v("Formation des Keys Users , webinars etc")]),_c('li',[_vm._v("Assistance au démarrage")]),_c('p',[_vm._v(" Travailler ensemble, c’est aussi l’occasion de créer de liens forts, d’être sur le terrain pour connaitre vos réalités et être ainsi capable jour après jour de vous proposer des solutions plus adaptées à vos besoins. ")])])]),_c('div',{staticClass:"block-default-image margin-left-default"},[_c('img',{attrs:{"src":require("./../assets/images/experts.jpg"),"alt":"des experts à votre écoute"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mission-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row responsive-column-reverse"},[_c('div',{staticClass:"block-default-image margin-left-default"},[_c('img',{attrs:{"src":require("./../assets/images/notre-service-clients.jpg"),"alt":"notre service client"}})]),_c('div',{staticClass:"block-default-text propos-block-default-text"},[_c('p',{staticClass:"block-default-text propos-block-default-text"}),_c('h2',[_vm._v("Notre service client")]),_c('p',[_vm._v("Une fois le projet terminé, nous restons à votre disposition et votre écoute via notre service client pour répondre à toutes vos questions et vos problématiques.")]),_c('p',[_vm._v("Nous pouvons à tout moment organiser et diffuser des formations sur de nouvelles fonctionnalités.")]),_c('p',[_vm._v("Notre solution évolue grâce à vous :")]),_c('p',[_vm._v("Parce que nous avons à cœur de faire de HUMATCH l’outil qui réponde à toutes vos problématiques, nous récoltons régulièrement vos besoins pour définir les fonctionnalités de demain.")])])])])])
}]

export { render, staticRenderFns }