<template>
  <div>
    <section class="acquisition-of-talents-section">
      <div class="container">
        <div class="simplifier">
          <div class="simplifier-content">
            <div class="row">
              <div class="sous-titre simplifier-title">
                <h2>
                  Prêt à simplifier votre gestion<br />des ressources humaines ?
                </h2>
              </div>
            </div>
            <div class="planifier">
                <div class="demandeDemo marginBottom20">
                  <router-link to="/demo" class="demo-request texteDemande">Planifier une démo</router-link
                  >
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "simplifier",
};
</script>

<style scoped>
</style>
