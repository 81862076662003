<template>
  <div>
    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container">
        <div class="row">
          <div class="sous-titre marginTop100">
            <h2>Approuver Humatch</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>nous sommes convaincus que chaque entreprise est unique. C'est pourquoi nous proposons des solutions SIRH sur mesure pour PME et ETI, adaptées à vos besoins spécifiques.</h3>
          </div>
        </div>
        <div class="troisColumn-wrap padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/IconSimplifier.svg" class="iconSimplifie">
            <h3 class="marginTop20">Notre approche</h3>
            <p class="simplifieTexte marginTop20">
              - Comprendre vos besoins et vos défis RH
            </p>
            <p class="simplifieTexte">
              - Analyser vos processus existants
            </p>
            <p class="simplifieTexte">
              - Concevoir une solution SIRH qui répond à vos exigences
            </p>
            <p class="simplifieTexte">
              - Développer des modules spécifiques si nécessaire
            </p>
            <p class="simplifieTexte">
              - Accompagner votre transformation RH
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/IconTemps.svg" class="iconSimplifie">
            <h3 class="marginTop20">Avec notre expertise, vous bénéficiez d'un SIRH qui vous permet de :</h3>
            <p class="simplifieTexte marginTop20">
              - Automatiser les tâches administratives chronophages
            </p>
            <p class="simplifieTexte">
              - Gérer vos talents de manière efficace
            </p>
            <p class="simplifieTexte">
              - Prendre des décisions RH basées sur des données fiables
            </p>
            <p class="simplifieTexte">
              - Améliorer la performance de votre entreprise
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/IconPers.svg" class="iconSimplifie">
            <h3 class="marginTop20">Plus qu'un simple logiciel, nous sommes un véritable partenaire RH</h3>
            <p class="simplifieTexte marginTop20">Nous vous accompagnons tout au long de votre projet, de la conception à la mise en place et à la maintenance de votre solution SIRH.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "approuver"
}
</script>

<style scoped>


</style>
