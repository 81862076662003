<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Gestion des Congés et Absences:<br/> Simplifiez et optimisez la gestion des congés</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gagnez du temps et de la sérénité avec notre solution SIRH sur mesure pour PME et ETI, dotée d'un module de gestion des congés et absences performant.</H2>
            <h3 class="sous-haut-page">
              Les avantages d'un module de gestion des congés et absences sur mesure:<br/>

              - Gain de temps et d'efficacité pour les équipes RH et les managers<br/>
              - Réduction des erreurs et des litiges<br/>
              - Amélioration de la communication et de la transparence<br/>
              - Autonomie et responsabilisation des collaborateurs<br/>
              - Meilleure gestion des ressources humaines<br/>
              - Développez votre potentiel RH et boostez la performance de votre entreprise avec un module de gestion des congés et absences sur mesure.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fconges.svg?alt=media&token=578642c1-2c11-4bcb-8e6a-d7074b8dce6a" alt="logiciel gestion des congés">
          </div>
        </div>
      </div>
    </section>
    <presentation-fonction
        titre='Personnalisez<br> votre politique de congés et valider <br>les congés en ayant une vision du planning des équipes'
        sousTitre="Le module de gestion des congés et absences permet de digitaliser l'ensemble du workflow de validation des congés"
        v-bind:modules='["conges-perso", "conges-autonomie", "conges-rapide"]'
        sens="gauche"
        balise="un logiciel de gestion des congés pour automatiser votre workflow de validation"
    ></presentation-fonction>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Gagnez du temps, digitalisez l'ensemble du processus des demandes de congés</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie" alt="les collaborateurs peuvent anticiper leurs congés avec l'application de congés">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Être acteur de sa gestion de congés, en demandant via son portail collaborateur.<br>
              Accéder à tout moment au solde de congés disponible avec notre logiciel de gestion des congés.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">Exportez simplement les congés pour la paie</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="Visualisez l'état des présences avec le logiciel de gestion des congés">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Ayez de la visibilité sur la présence de l'ensemble de votre équipe.<br>
              Approuvez, refusez les demandes de vos collaborateurs rapidement et simplement.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Avantages du module de gestion des congés</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
              <img src="./../assets/images/gta.svg" alt="logiciel gta">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <p>
                Automatisation du processus de demande de congés: Les employés peuvent demander et suivre leurs congés en ligne, sans avoir à utiliser des formulaires papier ou à envoyer des e-mails.
              </p>
              <p>
                Visibilité en temps réel: Les managers peuvent voir en temps réel les congés demandés et approuvés, ce qui aide à planifier les remplacements et à éviter les conflits d'agenda.
              </p>
              <p>
                Amélioration de la communication: Les logiciels de gestion des congés permettent une communication plus efficace entre les employés, les managers et les RH, ce qui peut réduire les erreurs et les retards.
              </p>
              <p>
                Conformité avec les lois et les politiques: Les logiciels de gestion des congés peuvent aider les entreprises à se conformer aux lois et aux politiques en matière de congés, ce qui peut réduire les risques juridiques et financiers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Conges',
  metaInfo: {
    title: "Module Gestion des Congés et Absences : Simplifiez et optimisez la gestion des congés | Humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gestion-des-conges'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Donnez de la visibilité à tous et de l’autonomie à vos collaborateurs sur les demandes de congés avec notre logiciel de gestion des congés. Exportez simplement toutes ces informations vers votre logiciel de paie.'
      }
    ]
  },
  components: { PresentationFonction, Menu, Bottom, simplifier},
  data(){
    return {
      menu:"conges"
    }
  }
}
</script>
