<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module GPEC : Développez vos talents et optimisez votre performance RH</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Anticipez les besoins en compétences de votre entreprise et accompagnez le développement de vos talents avec notre solution SIRH sur mesure pour PME et ETI.</H2>
            <h3 class="sous-haut-page">
              Nos fonctionnalités:<br/>
              - Cartographie des compétences: Identifiez les compétences clés de vos collaborateurs et les besoins de votre organisation.<br/>
              - Évaluation des potentiels: Mesurez les aptitudes et les motivations de vos collaborateurs pour identifier les talents à haut potentiel.<br/>
              - Planification des parcours professionnels: Définissez des plans de développement individualisés et accompagnez vos collaborateurs dans leur évolution de carrière.<br/>
              - Gestion des formations: Organisez et suivez les formations en interne et en externe.<br/>
              - Suivi des performances: Mesurez l'impact des formations et des actions de développement sur la performance individuelle et collective.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fgpec.svg?alt=media&token=e276b69b-bd83-4c8c-9f02-18e2c1fdf7c2" alt="logiciel gpec">
          </div>
        </div>
      </div>
    </section>

    <div class="marginBottom20 marginTop20"></div>

    <presentation-fonction
        titre='Développez<br> les talents de votre entreprise'
        sousTitre="Identifiez les compétences clés, établissez une cartographie pour pouvoir évaluer et planifier des formations, évolutions, ..."
        v-bind:modules='["gpec-referentiel", "gpec-matrice", "gpec-cible"]'
        sens="gauche"
        balise="la GPEC permet de développer les talents dans votre organisation"
    ></presentation-fonction>

    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container">
        <div class="row">
          <div class="sous-titre marginTop100">
            <h2>Enjeux et construction d'une GPEC performante </h2>
          </div>
        </div>
        <div class="row padding80">
          <div class="deuxColumn padding20 block-default-text">
            <img src="./../assets/images/goal.svg" class="iconSimplifie" alt="logiciel GPEC">
            <h3 class="marginTop20">Pourquoi la GPEC est un enjeu majeur ?</h3>
            <p class="simplifieTexte marginTop20">
              Vous devez afin de répondre à vos enjeux stratégiques, opérationnels, humains:</p>
            <ul>
              <li>Maintenir et/ou développer vos activités avec les compétences adaptées,</li>
              <li>Renforcer, développer l’autonomie professionnelle de vos équipes, collaborateurs (trices),</li>
              <li>Optimiser la mobilité interne, vos recrutements, intégrations,</li>
              <li>Limiter le TURNOVER,</li>
              <li>Mettre en oeuvre des formations de qualité,</li>
              <li>Evaluer les compétences de vos équipes sur la base de données objectives liées à leur poste etc.</li>
            </ul>
          </div>
          <div class="deuxColumn padding20 block-default-text">
            <img src="./../assets/images/build-gpec.svg" class="iconSimplifie" alt="construisez votre GPEC avec notre logiciel">
            <h3 class="marginTop20">Construire simplement et efficacement votre Gestion Prévisionnelle des Emplois et
              des Compétences (GPEC)</h3>
            <p class="simplifieTexte marginTop20">
              HUMATCH vous apporte une approche unique avec :</p>
            <ul>
              <li>La possibilité de mettre en œuvre une GPEC partielle ou complète selon vos besoins au travers de notre
                solution digitale,
              </li>
              <li>Une solution, un service via un outil facile d’utilisation sur votre ordinateur, votre tablette, votre
                téléphone,
              </li>
              <li>Une base de données (métiers, compétences, fonctions totalement décrites) pour vous permettre de
                gagner du temps et de l’argent dans la mise en œuvre de votre GPEC,
              </li>
              <li>Un support pédagogique vous permettant de créer par vous-même votre GPEC avec votre référentiel de
                compétence, de fonction, vos filières d’emplois etc.,
              </li>
              <li>Un support conseil adaptatif selon vos besoins</li>
            </ul>
          </div>
        </div>
      </div>
    </section>


    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>La GPEC vous permet de suivre les compétences clés dans votre entreprise,<br> de les évaluer et de
              planifier des actions pour les améliorer, mais aussi pour faire évoluer vos collaborateurs</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie" alt="des collaborateurs motivé avec le logiciel GPEC">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Les salariés d'une entreprise peuvent également tirer grandement profit de l'utilisation par les managers
              et les services RH d'un outil de management des compétences. Grâce à cela, ils peuvent évoluer plus
              facilement vers de nouveaux postes et atteindre leur plein potentiel. Ils seront également mieux outillés
              pour remplir leurs nouvelles missions.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="Anticipez vos besoins en talent avec notre logiciel GPEC">
            <h3 class="marginTop20">Service RH</h3>
            <ul class="simplifieTexte marginTop20">
              <li>- Pilotez les talents avec une meilleure anticipation des besoins de l'entreprise en établissant une cartographie des compétences</li>
              <li>- Identifiez les postes disponibles en interne et les collaborateurs succeptibles de pouvoir postuler</li>
              <li>- Mettez en place des formations en adéquation avec votre stratégie d'acquisition de compétences pour anticiper les compétences manquantes</li>
              <li>- Visualisez les compétences des collaborateurs</li>
            </ul>
            <p class="simplifieTexte">
              L'utilisation d'un logiciel de GPEC permet au service RH de jouer plus efficacement son rôle qui est de
              combler les besoins de l'entreprise en matière de personnel et de compétences. Les logiciels de gestion
              des compétences mettent en évidence les postes disponibles en interne et permettent une meilleure
              anticipation des besoins de la structure en fonction des métiers. Ils offrent également une vue globale et
              fiable des compétences des collaborateurs.
            <p class="simplifieTexte">
              Le module de GPEC permettent de mettre en place des formations en adéquation avec les besoins en
              compétences et de réaffecter si nécessaire certains collaborateurs vers les postes qui favoriseront le
              développement de leurs talents.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="le logiciel GPEC permet une anticipation des besoins en terme de talents">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">
              Pour les managers, la GPEC est un véritable atout dans la gestion des équipes. Elle permet de visualiser
              de façon instantanée le niveau de compétences de tous les membres de l'équipe ainsi que les besoins en recrutement ou en formation. La
              motivation de l'équipe et la fidélisation des talents sont ainsi simplifiées grâce à un logiciel de
              gestion des compétences.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Qu'est-ce qu'un logiciel de GPEC<br> (gestion des compétences) ?</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication marginTop50">
              <img src="./../assets/images/gpec.svg" alt="faire évoluer les talents de votre entreprise">
            </div>
            <div class="presentation-text textExplication marginTop20">
              <p>
                Un logiciel de GPEC (Gestion des Ressources Humaines et de la Planification de l'Emploi et des Compétences) est un type de logiciel utilisé pour gérer les processus liés à la gestion des ressources humaines, tels que la planification de l'emploi, l'évaluation des performances, la gestion des compétences et des talents, etc. Il permet aux entreprises de mieux comprendre leurs employés, d'aligner les objectifs de l'entreprise avec les objectifs des employés et d'optimiser l'utilisation des compétences et des talents internes.
              </p>
              <h3>Permettre un suivi et un pilotage simplifié des talents</h3>
              <p>
                Les logiciels de GPEC, et plus particulièrement notre application, permettent un suivi et un pilotage simplifié des talents en permettant aux entreprises de mieux comprendre les compétences et les potentiels de leurs employés, de suivre leur évolution et de planifier leur développement futur. Ils permettent également de mettre en place des programmes de formation et de carrière adaptés aux besoins individuels des employés et de l'entreprise. Cela permet de soutenir la croissance des employés et de l'entreprise de manière efficace et cohérente.
              </p>
              <p>
                Notre logiciel de gestion des compétences est donc un outil informatique qui permet un management
                dynamique, intelligent et rapide des compétences en entreprise. Il aide le département RH à évaluer les
                compétences, valoriser les parcours, renforcer les capacités des collaborateurs qui en ont besoin, définir les
                métiers… C'est un outil accessible, simple d'utilisation et le plus souvent basé sur le cloud. Notre module de
                GPEC propose toutes les fonctionnalités indispensables pour un bon suivi des compétences et un pilotage simplifié des
                talents. Ce logiciel de gestion des compétences est disponible sur smartphone, PC et tablette.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";


export default {
  name: 'Gpec',
  metaInfo: {
    title: "GPEC : Développez vos talents et optimisez votre performance RH",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gpec'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Le logiciel de GPEC d\'humatch permet une gestion prévisionnelle des emplois et des compétences de votre entreprise. Une analyse rapide des compétences et de vos besoins vous aide dans l\'optimisation de vos ressources humaines et dans l\'établissement des plan d\'actions pour les formations, évolutions,...'
      }
    ]
  },
  data() {
    return {
      menu: "gpec"
    }
  },
  components: {Menu, Bottom, PresentationFonction, simplifier}
}
</script>
