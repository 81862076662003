<template>
  <div>
    <Menu></Menu>

    <section class="presentation-section">
      <div class="article marginTop20 marginLeft70">
        <router-link v-if="tags.includes('gpec')" to='/sirh/logiciel-gpec' class="grey marginRight20"><strong>Logiciel gpec</strong></router-link>
        <router-link v-if="tags.includes('recrutement')" to='/sirh/logiciel-de-recrutement' class="grey marginRight20"><strong>Logiciel recrutement</strong></router-link>
        <router-link v-if="tags.includes('entretien')"  to='/sirh/logiciel-gestion-entretien' class="grey marginRight20"><strong>Logiciel entretien</strong></router-link>
        <router-link v-if="tags.includes('onboarding')" to='/sirh/logiciel-integration-onboarding-employes' class="grey marginRight20"><strong>Logiciel onboarding</strong></router-link>
        <router-link v-if="tags.includes('corerh')" to='/sirh/core-rh' class="grey marginRight20"><strong>Logiciel core rh</strong></router-link>
        <router-link v-if="tags.includes('gta')" to='/sirh/logiciel-gestion-des-temps' class="grey marginRight20"><strong>Logiciel gestion des temps</strong></router-link>
        <router-link v-if="tags.includes('conges')" to='/sirh/logiciel-gestion-des-conges' class="grey"><strong>Logiciel gestion des congés</strong></router-link>
        <hr>
        <router-link to='/' class="grey">Accueil</router-link>/<router-link to="/actualite-digitalisez-vos-rh" class="grey">Actualité</router-link>/
        <strong><span class="grey">{{ article[0].title }}</span></strong><br>
      </div>
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>{{ article[0].title }}</h1>
              <span class="line-headline"></span>
            </div>
          </div>
          <div class="fonction-image marginTop100">
            <img v-bind:src="article[0].image" v-bind:alt="article[0].alt" class="img-responsive">
          </div>
        </div>
      </div>
    </section>
    <section class="blog">
      <div class="container">
        <div class="marginTop20 textDescriptionArticle">
          <div v-html="article[0].texte">
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import db from '../shared/db';
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Article',
  metaInfo() {
    return {
      title: this.titre,
      link: [
        {
          rel: 'canonical', href: 'https://humatch.fr/actualite-rh/' + this.slug
        }
      ],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.description
        }
      ]
    }
  },
  components: {Menu, Bottom },
  data(){
    return {
      article:{},
      menu:"actu",
      titre:"",
      description:"",
      slug:"",
      tags:[],
    }
  },
  methods:{
    async selectArticle() {
      const vm = this;
      db.getArticle(this.$route.params.slug).then((snapshot) => {
          this.article = snapshot
          this.titre = vm.article[0].title;
          this.description = vm.article[0].description;
          this.slug = vm.article[0].slug;
          const lestags = vm.article[0].tags;
          lestags.forEach((element)=> {
            this.tags.push(element);
          })
      })
    }
  },
  created() {
    this.selectArticle(db);
  },
}
</script>

