<template>
  <section class="history-section">
    <div class="container">
      <div class="row responsive-column-reverse">
        <div class="block-default-image">
          <img
              src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Favantages.svg?alt=media&token=c6f8dde9-99f1-4e19-8b5c-6513bc6764e8"
              alt="Avantages de la marketplace humatch">
        </div>
        <div class="block-default-text propos-block-default-text">
          <h2>Une marketplace pour accélerer la mise en  place</h2>
          <p>
            Humatch MarketPlace propose des modèles de contrats, d'entretiens, fiches de postes adaptées à votre
            secteur d'activité. Ces modèles sont proposés par les professionnels de votre secteur ou par les
            experts Humatch.
          </p>
          <p>Vous pouvez ainsi créer en quelques clics tous les paramétrages et gagner des jours de travail. Il ne vous
            reste plus qu'à intégrer, manuellement ou automatiquement vos collaborateurs et bénéficier d'un SIRH performant.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "avantages"
}
</script>

<style scoped>

</style>
