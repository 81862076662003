<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
              <div class="headline">
                <h1>Nouveautés<br>du logiciel Humatch</h1>
                <span class="line-headline"></span>
              </div>
            <p class="soutien">
              Nos équipes travaillent en permanence sur l'ajout de fonctionnalités de notre logiciel RH.<br>
            </p>
          </div>
          <div class="presentation-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fnouveautes.svg?alt=media&token=8fbe9926-87fc-47a5-85ed-221d1adee3be" alt="nouveautes humatch">
          </div>
        </div>
      </div>
    </section>

    <section v-for="nouveaute in nouveautesList" v-bind:key="nouveaute.id">
      <div class="container" >
        <div class="first-nouveautes">
          <div class="item">
            <div class="block-nouveaute-text margin-left-default">
              <span class="post-date">Release note : {{ nouveaute.periode }}</span>
              <h3 class="marginTop50">{{ nouveaute.resume }},...</h3>
              <div class="voirNouveau marginTop20" v-if="voir!==nouveaute.id">
                <a class="texteDemande"  v-on:click="selectNouveaute(nouveaute.id)">En savoir plus</a>
              </div>
              <div class="fermeNouveau marginTop20" v-if="voir===nouveaute.id">
                <a class="texteDemande"  v-on:click="fermeNouveaute()">Fermer</a>
              </div>
               <div v-show="voir===nouveaute.id" class="marginTop20">
                 <p class="complete-list" v-html="nouveaute.texte"></p>
               </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import db from '../shared/db';
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";


export default {
  name: 'Nouveautes',
  components: {Menu, Bottom },
  metaInfo: {
    title: "Nouveautés produit humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/nouveautes-produit'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "Nos équipes travaillent en permanence sur l'ajout de fonctionnalités de notre logiciel RH pour répondre aux besoins métiers de nos clients"
      }
    ]
  },
  data(){
    return {
      nouveautesList:[],
      menu:"actu",
      voir:"",
    }
  },
  methods:{
    async getNouveautes() {
     db.getNouveautes().then((snapshot) => {
       this.nouveautesList = snapshot
     })
    },
    selectNouveaute(index){
      this.voir = index;
    },
    fermeNouveaute(){
      this.voir ="";
    }
  },
  created() {
    this.getNouveautes(db);
  }
}
</script>

<style scoped>

li{
  list-style-image: url("./../assets/images/purple-point.svg");
  padding-left: 18px;
}

</style>

