<template>
  <header>
    <div class="container-fluid">
      <div class="row align-items-center" id="navbar-top">
        <div class="header-logo" v-on:mouseover="hideTous">
          <router-link to="/"><img src="./../assets/images/logo.svg" alt="SIRH Humatch"/></router-link>
        </div>
        <div
            class="menu-burger__header"
            :class="{ open: burgerOpen }"
            @click="burgerOpen = !burgerOpen"
        >
          <span></span>
        </div>
        <nav class="header-menu">
          <ul>
            <li class="nav-link" v-on:mouseover="showMenu">
              <a href="#">
                Modules disponibles
                <span class="flecheBas"></span>
              </a>
            </li>
            <div v-show="statut">
              <sousmenufonctions @change="hideMenu"></sousmenufonctions>
            </div>
            <div v-show="pourquoi">
              <sousmenupourquoi @change="hidePourquoi"></sousmenupourquoi>
            </div>
            <li class="nav-link" v-on:mouseover="showRessource">
              <a href="#">
                Ressources
                <span class="flecheBas"></span>
              </a>
            </li>
            <div v-show="ressource">
              <sousmenuressources @change="hideRessource"></sousmenuressources>
            </div>
            <li class="nav-link" >
              <a href="https://wiboz.com/">
                Gestion des contrats
              </a>
            </li>
          </ul>
        </nav>
        <MobileMenu :menuOpen="burgerOpen"/>
        <div class="demandeDemoMenu">
          <router-link to="/demo" class="demo-request texteDemande">Échanger sur votre projet</router-link>
        </div>
        <div class="header-right">
          <ul>
            <li class="nav-link">
              <a href="https://app.humatch.fr" target="_blank">Se connecter</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import Sousmenufonctions from "@/components/sousmenuFonctions";
import Sousmenuressources from "@/components/sousmenuRessources";
import MobileMenu from "@/components/MobileMenu";
import Sousmenupourquoi from "./sousmenuPourquoi";

!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ewiKinF3PHIk1ClHe3OpEQpfkJCYRAAX";analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("ewiKinF3PHIk1ClHe3OpEQpfkJCYRAAX");
  analytics.page();
}}();

export default {
  name: "Menu",
  components: {Sousmenupourquoi, Sousmenufonctions, Sousmenuressources, MobileMenu},
  data() {
    return {
      statut: false,
      ressource: false,
      pourquoi: false,
      burgerOpen: false,
    };
  },
  methods: {
    showMenu() {
      this.statut = true;
      this.pourquoi = false;
      this.ressource = false;
    },
    hideMenu() {
      this.statut = false;
    },
    showRessource() {
      this.ressource = true;
      this.pourquoi = false;
      this.statut = false;
    },
    showPourquoi() {
      this.pourquoi = true;
      this.statut = false;
      this.ressource = false;
    },
    hideRessource() {
      this.ressource = false;
    },
    hidePourquoi() {
      this.pourquoi = false;
    },
    hideTous() {
      this.statut = false;
      this.ressource = false;
      this.pourquoi = false;
    },
  },
  mounted() {
    document.documentElement.style.overflowY = '';
    const nav = document.getElementById("navbar-top");
    window.addEventListener("scroll", () => {
      if (window.pageYOffset >= nav.offsetHeight) {
        nav.classList.add("sticky");
      } else if (window.pageYOffset <= nav.offsetHeight) {
        nav.classList.remove("sticky");
      }
    });
  },
  watch: {
    burgerOpen: {
      handler(val) {
        if (val) {
          document.documentElement.style.overflowY = 'hidden'
        } else {
          document.documentElement.style.overflowY = '';
        }
      },
    },

  },

};
</script>
<style scoped>
</style>
