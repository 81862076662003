<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Interim<br>Fiches de postes, <br>Entretiens...</h1>
              <span class="line-headline"></span>
            </div>
            <H2></H2>
            <h3 class="sous-haut-page">
              Humatch permet aux partenaires qualifiés de proposer des templates de fiches de postes, d’entretiens,<br>
              contrats… et de les mettre à disposition sur une marketplace. <br>
              La marketplace est une boutique en ligne qui vous aide à trouver, sélectionner et déployer rapidement
              votre application.
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Finterim.png?alt=media&token=985e1217-5925-4f62-b22d-75cf074b6eef"
                alt="Intégration et rdd de votre SIRH">
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-text propos-block-default-text">
            <h2>Les fonctionnalités proposées</h2>
            <p>
              Après validation chaque partenaire peut créer ses différents modèles et décider de les mettre gratuitement
              à disposition de tous les utilisateurs de l'application ou de fournir un code d'authenfication permettant à leurs
              clients/adhérents de chargé le modèle.
            </p>
            <ul>
              <li>Vous choississez ce que vous voulez mettre à disposition de vos clients.</li>
              <li>Vous définissez le prix de votre prestation, nous n'intervenons pas dans la transaction et ne prenons pas de frais sur celle ci.</li>
              <li>Vous pouvez suivre les templates que vos clients ont téléchargés.</li>
              <li>Vous pouvez suivre les clients qui utilisent vos modèles</li>
              <li>Vous pouvez créer autant de modèle que vous le souhaitez, les mettre en ligne, les retirer,...</li>
            </ul>
          </div>
          <div class="block-default-image">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fmission.svg?alt=media&token=648dc458-fc5e-4dcb-a20e-392a9a80a9fd"
                alt="Capitalisez sur vos précédentes missions">
          </div>
        </div>
      </div>
    </section>
    <avantages></avantages>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import avantages from "@/components/avantages";

export default {
  name: 'Interim',
  metaInfo: {
    title: "Proposez des modèles de contrats, fiches de postes, ... à vos clients et capitalisez sur votre expertise",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/partenaire'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: ''
      }
    ]
  },
  components: {Menu, Bottom, simplifier, avantages },
  data() {
    return {
      menu: ""
    }
  }
}
</script>
