<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Onboarding:<br/> Accélérez l'intégration<br/> de vos nouveaux talents
              </h1>
              <span class="line-headline"></span>
            </div>
            <H2>Réduisez le temps d'adaptation et optimisez l'engagement de vos nouveaux collaborateurs avec notre solution SIRH sur mesure pour PME et ETI.</H2>
            <h3 class="sous-haut-page">
              Nos fonctionnalités:<br/>

              - Formalités administratives: Automatisez la collecte des documents et simplifiez les démarches administratives.<br/>
              - Parcours d'intégration personnalisé: Adaptez le contenu et les étapes en fonction du profil et du poste du nouveau collaborateur.<br/>
              - Mentoring et suivi: Favorisez l'intégration et le sentiment d'appartenance en désignant un mentor et en assurant un suivi régulier.<br/>
              - Feedback et évaluation: Mesurez la satisfaction des nouveaux collaborateurs et optimisez votre processus d'onboarding.<br/>
              - Ressources et informations: Mettez à disposition toutes les informations utiles pour une prise de fonction rapide et efficace.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fonboarding.svg?alt=media&token=72c47ddf-602e-4e19-b390-d702032ef72a" alt="logiciel integration des collaborateurs">
          </div>
        </div>
      </div>
    </section>

    <div class="marginBottom20 marginTop20"></div>
    <presentation-fonction
        titre='Gagnez du temps en automatisant<br> le suivi des contrats, avenants <br>et la validation des documents (RIB, Attestation de domicile,...)'
        sousTitre="L'application permet d'établir des contrats types ainsi que des documents types et de définir des échéances par type de document."
        v-bind:modules='["onboarding-contrat", "onboarding-document"]'
        sens="gauche"
        balise="logiciel onboarding pour gagner du temps"
    ></presentation-fonction>

    <presentation-fonction
        titre='Optimisez le suivi du matériel remis aux collaborateurs<br> et proposez des formations et des tests'
        sousTitre="A partir du catalogue du matériel, affectez celui-ci à vos collaborateurs et intégrez la liste dans un document.
        Ajoutez des formations et des tests pour valider des points important dans votre processus"
        v-bind:modules='["onboarding-materiel", "onboarding-formation"]'
        sens="droite"
        balise="Suivez votre matériel avec notre logiciel intégration"
    ></presentation-fonction>

    <presentation-fonction
        titre='Pour le onBoarding et le offBoarding,<br> ajoutez une todo liste <br>et suivez la validation du parcours de votre collaborateur'
        sousTitre="Personnalisez les todo listes, suivez leurs réalisations et valider le parcours du nouveau collaborateur à chaque étape de son intégration"
        v-bind:modules='["onboarding-todo", "onboarding-flow"]'
        sens="gauche"
        balise="intégrez simplement une todo liste dans notre logiciel onboarding"
    ></presentation-fonction>

    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages d'un onboarding réussi<br>dans une solution tout-en-un </h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Gagnez du temps, digitalisez l'ensemble du processus de onboarding et offBoarding pour donner une bonne première impression</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie" alt="travaillez votre marque employeur avec un logiciel d'intégration performant">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Permettez à vos collaborateurs de retrouver toutes les informations de leurs profils, documents administratifs
              et contractuels ainsi que les formations. Ils accèdent aux trombinoscopes de leur entreprise pour mieux
              identifier les rôles et noms de chacun et ils sont alertés de leurs taches à faire.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="pilotez les intégrations et les départs avec un logiciel d'onboarging">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">
              Renforcez la culture d’entreprise en communiquant, dès leur arrivée, à vos collaborateurs un sentiment d’appartenance à votre organisation, qui sera source de motivation dans leur prise de poste.
            </p>
            <p class="simplifieTexte">
              Automatisez, créez et envoyez facilement vos contrats avec la signature électronique. Demandez, partagez et ne perdez plus les documents importants de vos collaborateurs, affectez et suivez les matériels mis à disposition.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="le logiciel d'onboarding permet de bien accueillir les nouveaux membres de votre équipe">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">
              Suivez l’intégration de vos nouveaux collaborateurs, affectez-leur du matériel, des compétences, tâches, formations et objectifs. Préparez sereinement l’arrivée de nouveaux collègues en suivant la TO- Do list d’intégration, soyez ainsi sûr que tout est prêt à temps.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Application d'intégration des nouveaux collaborateurs<br>l'essentiel à savoir</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
              <img src="./../assets/images/integration-reussi.svg" alt="intégration réussi de vos salariés">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2>Onboarding RH : de quoi s'agit-il ?</h2>
              <p>
                Onboarding est un anglicisme qui signifie littéralement &laquo; intégration &raquo; en français. Dans le domaine de la gestion
                du personnel en entreprise, elle fait référence à l'art d'<strong>accueillir ou d'intégrer les nouveaux
                collaborateurs</strong>. L'intégration RH est donc un ensemble de processus qui sont mis en &oelig;uvre par l'entreprise pour
                faciliter la prise de poste des collaborateurs ou des travailleurs fraîchement recrutés.
              </p>
              <p>
                Le processus d'Onboarding démarre à la fin de l'entretien d'embauche d'un candidat dont le profil est validé par les ressources
                humaines. Cela prend fin le plus souvent entre 3 mois et un an après son entrée effective dans l'entreprise. L'Onboarding RH
                joue un r&ocirc;le crucial dans la productivité et le dynamisme des nouveaux salariés d'une entreprise. Il influence également
                leur engagement au sein de l'entreprise et leur fidélité à la marque employeur. Pour ces différentes raisons, sa mise en place
                pose un défi particulier à toutes les entreprises, quelle que soit leur taille.
              </p>
              <h2>Fonctionnalités et atouts pour une entreprise d'adopter un logiciciel de onboarding</h2>
              <p>
                <strong>Processus standardisé</strong>: notre module d'onboarding permet de standardiser le processus d'intégration des nouveaux employés, ce qui garantit une expérience uniforme pour tous les employés.
            </p>
              <p>
                <strong>Personnalisation</strong>: Les entreprises peuvent personnaliser le processus d'onboarding en fonction de leurs besoins uniques, ce qui permet d'adapter le processus à leur culture d'entreprise.
              </p>
              <p>
                <strong>Économie de temps</strong>: Les processus manuels d'onboarding peuvent être chronophages. l'application d'onboarding automatise les tâches répétitives, ce qui libère du temps pour les employeurs pour se concentrer sur d'autres tâches importantes.
              </p>
              <p>
                <strong>Amélioration de la satisfaction des employés</strong>: Un processus d'onboarding efficace peut aider à améliorer la satisfaction des employés en veillant à ce qu'ils reçoivent les informations et les outils nécessaires pour effectuer leur travail.
              </p>
              <p>
                <strong>Suivi des progrès</strong>: Notre logiciel d'onboarding permet de suivre les progrès des nouveaux employés, ce qui aide les employeurs à évaluer leur progression et à établir des plans de développement pour les aider à atteindre leurs objectifs.
              </p>
              <p>
                <strong>Accès à distance</strong>: Les employeurs peuvent accéder à des informations sur les employés à distance, ce qui permet d'offrir un processus d'onboarding flexible pour les employés travaillant à distance.
              </p>
              <p>
                <strong>Mesure de l'efficacité</strong>: Les entreprises peuvent mesurer l'efficacité de leur processus d'onboarding en collectant des données sur la satisfaction des employés et les indicateurs de performance. Cela aide les employeurs à améliorer leur processus d'onboarding au fil du temps.
              </p>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="deuxColumn padding10 textExplication">
              <h2>L'importance d'utiliser un logiciel permettant l'accueil des nouveaux salariés</h2>
              <p>
                Pour les entreprises, le processus d'Onboarding RH est axé autour d'<strong>un certain nombre d'étapes clés</strong> :
              </p>
              <ul>
                <li>- préparation en amont de l'arrivée du nouveau collaborateur,</li>
                <li>- programmation de rendez-vous réguliers pour faire le point avec le nouveau collaborateur et s'assurer de sa bonne intégration,</li>
                <li>- attribution d'un mentor au nouveau salarié pour faciliter son intégration au sein de l'entreprise,</li>
                <li>- organisation des formations nécessaires à l'adaptation du nouveau salarié à ses nouvelles tâches et à son nouveau poste.</li>
              </ul>
              <p>
                La bonne marche de ce processus d'Onboarding nécessite que la transmission des informations entre les cadres RH et les managers
                se fasse en temps réel et de façon fluide. Les ressources humaines doivent également être en mesure de suivre avec facilité
                l'évolution et le processus d'intégration des nouveaux salariés afin de prendre les décisions stratégiques qui s'imposent.
              </p>
              <p>
                L'<strong>utilisation d'un logiciel d'Onboarding RH</strong> (encore appelé logiciel d'intégration des collaborateurs) est
                désormais une solution essentielle pour toutes les entreprises. Ces dernières souhaitent accélérer l'intégration des nouveaux
                collaborateurs grâce à un processus d'Onboarding performant et fluide. Une application d'Onboarding RH spécialisée comme celle
                de Humatch vous permet de gagner du temps et de simplifier le partage d'informations. Elle vous aide également à communiquer aux
                nouveaux collaborateurs dès leur arrivée un sentiment d'appartenance afin de les motiver au travail.
              </p>
            </div>
            <div class="deuxColumn padding10 textExplication">
              <h2>Les fonctionnalités clés du module onboarding Humatch</h2>
              <ul>
                <li>- gestion de la pré-intégration des collaborateurs,</li>
                <li>- création de contrats sur mesure,</li>
                <li>- suivi du matériel confié à chaque collaborateur,</li>
                <li>- création de formations pour les fiches de postes,</li>
                <li>- to-do list pour permettre aux responsables des ressources humaines en charge de l'Onboarding de suivre facilement le parcours du
                  nouveau collaborateur.</li>
              </ul>
              <p>
                Avec <strong>le module Onboarding RH comme nous proposons</strong>, vous profitez de toutes ces fonctionnalités de base et
                bien plus encore pour offrir une excellente expérience d'intégration à vos nouveaux salariés. N'hésitez pas à nous contacter
                pour en savoir plus au sujet de notre SIRH (Système d'Information Ressources Humaines).
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Onboarding',
  metaInfo: {
    title: "Module Onboarding : Accélérez l'intégration de vos nouveaux talents",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-integration-onboarding-employes'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Boostez votre processus d\'intégration des nouveaux employés avec notre logiciel d\'onboarding personnalisable. Simplifiez le parcours de vos employés avec une expérience fluide et efficace.'
      }
    ]
  },
  components: {PresentationFonction, Menu, Bottom, simplifier},
  data(){
    return {
      menu:"onboarding"
    }
  }
}
</script>
