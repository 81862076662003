<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Accompagnez les entreprises dans<br> leurs quêtes de performances</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Vous Donner la possibilité de jouer à armes égales avec les grandes entreprises</H2>
            <h3 class="sous-haut-page">
              Nous avons créé Humatch parce que nous pensons que vous pouvez faire beaucoup plus en vous
              débarrassant des tâches sans valeurs ajoutées automatisables.
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fpourquoi-humatch.avif?alt=media&token=cb4e6f06-75f3-4b86-bdb1-95473a4e1ffc" alt="pourquoi humatch">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fhistory-section.avif?alt=media&token=d61cb7b1-fa9a-4c6b-9446-cb66ad1b1066" alt="Histoire d'humatch">
          </div>
          <div class="block-default-text propos-block-default-text">
            <img class="propos-icon" src="./../assets/images/props-clock.svg" alt="clock">
            <h2>NOTRE HISTOIRE</h2>
            <span class="blue-text">HUMATCH est née d’un constat simple.</span>
            <p>Les services RH des TPE et PME perdent trop de temps sur des tâches chronophages à faibles valeurs ajoutées telles que :</p>
            <ul class="propos-list">
              <li>Le regroupement ou la diffusion d’informations,</li>
              <li>La gestion des tâches administratives,</li>
              <li>Les relances de collaborateurs et managers de leurs entreprises (campagnes
                d’entretien, validation, d’intégration).</li>
            </ul>
            <p>Les équipes RH gèrent donc manuellement et individuellement des actions qui pourraient être
              automatisées et groupées. Fort de ce constat, <strong>Noëlle Menuet</strong> et <strong>Chrys VLACHOU</strong>, souhaitaient
              concevoir une solution RH permettant aux entreprises de bénéficier d’une application de
              qualité indépendamment du budget. Capitalisant sur leurs expériences managériales dans les SI et les
              RH, ils ont créé l’application qu’ils auraient voulu utiliser dans leurs entreprises.</p>

          </div>
        </div>
      </div>
    </section>
    <section class="mission-section">
      <div class="container">
        <div class="row">
          <div class="block-default-text propos-block-default-text">
            <img class="propos-icon" src="./../assets/images/propos-goal.svg" alt="goal">
            <h2>
              NOTRE MISSION
            </h2>
            <p>Depuis sa création, l’ADN d’Humatch est d’apporter des solutions adaptées pour les
              ressources humaines et d’accompagner les <strong>TPE</strong>, PME et ETI dans leur digitalisation. </p>
            <p>Avec son logiciel SIRH, Humatch vous accompagnera à chaque étape de votre
              croissance et donnera du souffle à vos ressources humaines pour qu’elles se focalisent sur des
              tâches plus stratégiques.</p>
            <p>Vos collaborateurs gagnent en bien-être et en productivité, vous gagnez en image de marque et en
              compétitivité. C’est le choix gagnant !</p>
          </div>
          <div class="block-default-image margin-left-default">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fgoal.avif?alt=media&token=ac59869a-cee3-4c8c-bff0-69ad113bdb90" alt="Objectifs">
          </div>
        </div>
      </div>
    </section>
    <section class="strengths-section history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fnos-atouts.avif?alt=media&token=0a54d8b0-4429-484f-834b-ab1e80a77513" alt="nos atouts">
          </div>
          <div class="block-default-text propos-block-default-text">
            <img class="propos-icon" src="./../assets/images/hands-icon.svg" alt="horloge">
            <h2>NOS ATOUTS</h2>
            <ul>
              <li>Une interface simple et adaptée à tous vos collaborateurs </li>
              <li>Un accompagnement auprès des équipes lors de la mise en place de l’outil</li>
              <li>Une productivité de l’entreprise optimisée grâce à l’automatisation des processus RH.</li>
              <li>Une solution personnalisable afin de promouvoir la
                marque employeur</li>
              <li>Une solution accessible pour tous grâce à une tarification sur la consommation
                réelle.</li>
              <li>Une connexion sécurisée et une sauvegarde quotidienne de vos données.</li>
              <li>Un service disponible 24/24, 7j/7 avec une maintenance et MAJ automatiques.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Societe',
  metaInfo: {
    title: "Humatch créateur de solutions RH pour PME ETI",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/societe'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'l\'ADN d’Humatch est de permettre à toutes les PME ETI d\'automatiser les tâches administratives à faible valeur ajoutée, d’améliorer leur marque employeur, de manière simple et intuitive et ce pour un budget abordable'
      }
    ]
  },
  components: { Menu, Bottom},
  data(){
    return {
      menu:"societe"
    }
  },
}
</script>
