<template>
<div v-on:mouseleave="onChange">
  <div class="submenu">
    <div class="container">
      <router-link to='/sirh/pme' class="blockMenu first">
        <img src="./../assets/images/sirh-pme.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>PME</strong>
          <span class="sousTitreMenu">SIRH pour PME</span>
        </div>
      </router-link>
      <router-link to='/expertises/hcr' class="blockMenu second">
        <img src="./../assets/images/hotel.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Hôtellerie restauration</strong>
          <span class="sousTitreMenu">SIRH pour HCL</span>
        </div>
      </router-link>
      <router-link to='/expertises/ehpads' class="blockMenu third">
        <img src="./../assets/images/ehpad.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>EHPADS</strong>
          <span class="sousTitreMenu">SIRH pour santé</span>
        </div>
      </router-link>
      <router-link to='/expertises/btp' class="blockMenu forth">
        <img src="./../assets/images/btp.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>BTP</strong>
          <span class="sousTitreMenu">SIRH pour BTP</span>
        </div>
      </router-link>
      <router-link to='/expertises/retail' class="blockMenu firth">
        <img src="./../assets/images/retail.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Retail</strong>
          <span class="sousTitreMenu">SIRH pour le retail</span>
        </div>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "sousmenupourquoi",
  methods: {
    onChange(){
      this.$emit('change');
    }
  }
}
</script>

<style scoped>
.submenu {
  index: 1;
  width: 658px;
  height: 414px;
  background-color: #FAF6FE;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #99A1C7;
  position: fixed;
  margin-top: 80px;
  margin-left: -180px;
}

.subsection {
  position: absolute;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #496C7C;
}

.subSection1 {
  width: 98px;
  height: 24px;
  left: 48px;
  top: 32px;
}

.blockMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 281px;
  height: 96px;
  background: white;
  border-radius: 8px;
}

.first {
  top: 72px;
  left: 32px;
}

.second {
  top: 176px;
  left: 32px;
}

.third {
  top: 280px;
  left: 32px;
}

.forth {
  top: 72px;
  left: 345px;
}

.firth {
  top: 176px;
  left: 345px;
}

.blockMenu:hover {
  /* Light/Violet */
  background: #F4F5FE;
  border-radius: 8px;
}

.textMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 205px;
  height: 64px;
  left: 60px;
  top: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;
}

.sousTitreMenu {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.iconSousMenu {
  /* Icon */
  position: static;
  width: 28px;
  height: 28px;
  left: 6px;
  top: 48px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.flecheBas {
  position: static;
  width: 12px;
  height: 12px;
  left: calc(50% - 12px / 2 + 58px);
  top: calc(50% - 12px / 2);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
  background: url("./../assets/images/fleche-bas.svg");
}
</style>
