<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>L'accompagnement de votre projet SIRH : Notre engagement pour votre réussiteH</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Notre objectif : Vous accompagner tout au long de votre projet SIRH pour garantir sa réussite et vous permettre de tirer pleinement parti de notre solution.</H2>
            <br/><br/>
            <h2>Notre vision : Un projet réussi ne se résume pas à la simple digitalisation de vos processus RH. Il s'agit avant tout d'une transformation positive pour vos équipes, qui s'approprient la solution en toute sérénité et découvrent de nouveaux modes de travail plus efficients.</h2>
            <h3 class="sous-haut-page">
              Notre méthodologie:<br/>

              1. Accompagnement au diagnostic de l'organisation:<br/>

              - Analyse approfondie de vos besoins et de vos processus RH existants.<br/>
              - Identification des points d'amélioration et des objectifs à atteindre.<br/>
              - Élaboration d'une feuille de route personnalisée pour votre projet.<br/>
              <br/>
              2. Proposition de fonctionnalités avec adaptation ou création des modules nécessaires:<br/>

              - Mise à disposition de notre large gamme de modules SIRH standard.<br/>
              - Adaptation et personnalisation des modules pour répondre à vos besoins spécifiques.<br/>
              - Création de modules sur-mesure pour combler d'éventuels manques fonctionnels.<br/>
              <br/>
              3 - Notre engagement :<br/>

              - Une équipe d'experts dédiée à votre projet, à l'écoute de vos besoins et de vos attentes.<br/>
              - Une communication transparente et régulière tout au long du projet.<br/>
              - Un suivi rigoureux et des points d'étape réguliers pour garantir l'avancement du projet.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Faccompagnement.svg?alt=media&token=5ea89a9e-f148-4eba-95fa-da0914b93854" alt="Intégration et rdd de votre SIRH">
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Foutil-simple-et-intuitif.avif?alt=media&token=a8920afd-7513-493c-886a-01abe81f878d" alt="application simple et intuitive">
          </div>
          <div class="block-default-text propos-block-default-text">
            <h2>Outil simple et intuitif</h2>
            <p>
              Nous avons développé un outil simple et intuitif, accessible à tous niveau d’utilisation
              numérique, où vous pouvez vous-même, intégrer facilement vos données.
            </p>
            <p>
              Cependant, un petit coup de main peut s’avérer utile, en cas de manque de ressources SI ou fonctionnel
              en interne, ou simplement parce que changer de méthode n'est pas chose aisée, être accompagné est rassurant.
            </p>
            <p>Pour beaucoup le changement est stressant, c’est pourquoi nous savons qu’il est primordial de
              vous proposer un service vous permettant d’aborder le déploiement sereinement.</p>
            <p>La clé du succès de la prise en main d’un nouvel outil c’est :</p>
            <ul>
              <li>Connaitre les étapes de mises en œuvre et ce qu’elles impliquent comme action</li>
              <li>Accéder à ses données simplement</li>
              <li>Un cursus d’apprentissage rapide</li>
              <li>Se sentir accompagner, savoir à qui demander de l’aide</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-text propos-block-default-text">
            <h2>
              Des experts à votre écoute pour vous accompagner
            </h2>
            <p>
              C’est pour cela que nous vous proposons des prestations de déploiement vous permettant de vous
              reposer sur notre expertise pour une mise en place fluide et rapide.
            </p>
            <ul>
              <li>Pilotage de projet</li>
              <li>Définition du périmètre</li>
              <li>Ateliers de paramétrage</li>
              <li>Ateliers de reprise de données</li>
              <li>Paramétrage outil</li>
              <li>Intégration des données</li>
              <li>Formation des Keys Users , webinars etc</li>
              <li>Assistance au démarrage</li>
              <p>
                Travailler ensemble, c’est aussi l’occasion de créer de liens forts, d’être sur le terrain
                pour connaitre vos réalités et être ainsi capable jour après jour de vous proposer des
                solutions plus adaptées à vos besoins.
              </p>

            </ul>
          </div>
          <div class="block-default-image margin-left-default">
            <img src="./../assets/images/experts.jpg" alt="des experts à votre écoute">
          </div>
        </div>
      </div>
    </section>
    <section class="mission-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-image margin-left-default">
            <img src="./../assets/images/notre-service-clients.jpg" alt="notre service client">
          </div>
          <div class="block-default-text propos-block-default-text">
            <p class="block-default-text propos-block-default-text">
            <h2>Notre service client</h2>
            <p>Une fois le projet terminé, nous restons à votre disposition et votre écoute via notre service
              client pour répondre à toutes vos questions et vos problématiques.</p>
            <p>Nous pouvons à tout moment organiser et diffuser des formations sur de nouvelles
              fonctionnalités.</p>

            <p>Notre solution évolue grâce à vous :</p>
            <p>Parce que nous avons à cœur de faire de HUMATCH l’outil qui réponde à toutes vos problématiques,
              nous récoltons régulièrement vos besoins pour définir les fonctionnalités de demain.</p>
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: 'Accompagnement',
  metaInfo: {
    title: "Sécurisez votre projet avec notre accompagnement",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/accompagnement'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Pour nous, un projet réussi, ce n’est pas seulement l’optimisation & la digitalisation de vos processus RH avec notre logiciel, mais c’est aussi, (surtout !) un utilisateur content, serein qui s’est approprié notre solution sans stress ni difficulté.'
      }
    ]
  },
  components: {Menu, Bottom},
  data(){
    return {
      menu:""
    }
  }
}
</script>
