<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Logiciel<br> de gestion des ressources humaine<br> pour PME</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gagnez du temps avec notre SIRH</H2>
            <h3 class="sous-haut-page">
              Remplacez vos fichiers excels en digitalisant l'ensemble des vos process RH
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/secteur%2Flogiciel-rh-pme.jpeg?alt=media&token=8de8d1ca-0817-44f4-a5a2-357476ebd6d5" alt="logiciel Rh pour pme">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <presentation-fonction
        titre="Gérez l'administratif et les entretiens de votre PME"
        sousTitre="Simplifiez la gestion des entretiens de vos collaborateurs"
        v-bind:modules='["core-rh", "entretien"]'
        balise="Logiciel de gestion des entretiens pour PME"
        sens="droite"
    ></presentation-fonction>
    <presentation-fonction
        titre="Recrutez simplement et rapidement <br>et suivez la prise de poste de vos collaborateurs"
        sousTitre="Automatisez les tâches répétitives de l'acquisition de candidats et soignez le onboarding"
        v-bind:modules='["recrutement", "onboarding"]'
        sens="gauche"
        balise="Logiciel de recrutement pour PME"
    ></presentation-fonction>
    <div class="marginBottom20 marginTop20"></div>
    <approuver></approuver>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2 id="logiciel">Digitalisation des RH dans les PME</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
                <img src="./../assets/images/recrutement-efficace.svg" alt="Permettre une digitalisation des RH faciles">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2>Les atouts d'un SIRH pour une PME</h2>
              <p>
              Un système de gestion des ressources humaines (SIRH) peut apporter de nombreux avantages à une PME. Voici quelques-uns des avantages les plus importants:
              </p>
              <p>
              Amélioration de l'efficacité : un SIRH peut automatiser les tâches administratives fastidieuses telles que la gestion des heures de travail, la gestion des salaires et la gestion des congés, ce qui peut améliorer considérablement l'efficacité de l'entreprise.
              </p>
              <p>
              Amélioration de la transparence : un SIRH peut fournir une vue centralisée sur les informations sur les employés, ce qui peut aider à améliorer la transparence et la communication au sein de l'entreprise.
              </p><p>
              Prise de décisions informées : en fournissant des données précises et actualisées sur les employés, un SIRH peut aider les dirigeants de l'entreprise à prendre des décisions informées concernant les ressources humaines.
            </p><p>
              Meilleure gestion de la performance : un SIRH peut aider les managers à suivre et à évaluer la performance des employés, ce qui peut améliorer la gestion de la performance au sein de l'entreprise.
</p><p>
              Amélioration de la conformité : un SIRH peut aider une PME à se conformer aux lois et réglementations en matière de ressources humaines, ce qui peut réduire les risques de poursuites judiciaires ou de sanctions.
            </p><p>
              Amélioration de la satisfaction des employés : en fournissant une gestion plus efficace des heures de travail, des congés et des salaires, un SIRH peut améliorer la satisfaction des employés et les inciter à rester avec l'entreprise.
            </p><p>
              En résumé, un SIRH peut apporter de nombreux avantages à une PME en améliorant l'efficacité, la transparence, la prise de décisions informées, la gestion de la performance, la conformité et la satisfaction des employés.
              </p>
            </div>
          </div>
          <div class="row marginBottom20 marginTop20">
            <div class="deuxColumn padding10 textExplication">
                <h2 class="hauteur25">L'aide au recrutement avec un logiciel de recrutement pour PME</h2>
              <p>
              Un logiciel de recrutement peut aider les PME à améliorer leur processus de recrutement en leur offrant les avantages suivants :
              </p>
              <p>
              Publication d'annonces de recrutement en ligne : les PME peuvent publier des annonces de recrutement en ligne sur des sites d'emploi populaires, ce qui leur permet de toucher un plus grand nombre de candidats potentiels.
              </p>
              <p>
              Gestion des candidatures : un logiciel de recrutement peut aider les PME à organiser et à suivre les candidatures reçues, ce qui leur permet de ne pas perdre de vue les candidats importants.
              </p>
              <p>
              Entretiens en ligne : certains logiciels de recrutement permettent aux PME de mener des entretiens en ligne avec les candidats, ce qui peut être plus pratique et moins coûteux que des entretiens en personne.
              </p>
              <p>
              Pré-sélection des candidats : un logiciel de recrutement peut aider les PME à pré-sélectionner les candidats en utilisant des algorithmes pour évaluer les compétences et les expériences des candidats.
              </p>
              <p>
              Évaluation des compétences : certains logiciels de recrutement, comme humatch peuvent inclure des outils d'évaluation des compétences pour aider les PME à évaluer les compétences des candidats en utilisant des tests et des simulations en ligne.
              </p>
              <p>
              Amélioration de la collaboration : un logiciel de recrutement peut aider les équipes de recrutement des PME à travailler ensemble en temps réel, ce qui peut améliorer la collaboration et la communication au sein de l'équipe.
              </p>
              <p>
              En conclusion, un logiciel de recrutement peut aider les PME à améliorer leur processus de recrutement en simplifiant la publication d'annonces de recrutement, en facilitant la gestion des candidatures, en permettant des entretiens en ligne, en aidant à la pré-sélection des candidats, en évaluant les compétences des candidats et en améliorant la collaboration au sein de l'équipe de recrutement.
              </p>
            </div>
            <div class="deuxColumn padding10 textExplication">
              <h2 class="hauteur25">Aide à la gestion des entretiens pour les PME avec notre logiciel de gestion des entretiens</h2>
              <p>
              Un logiciel de gestion des entretiens peut aider les PME à améliorer leur processus d'entretien en leur offrant les avantages suivants :
              </p>
              <p>
              Planification des entretiens : les PME peuvent planifier facilement les entretiens avec les candidats en utilisant un logiciel de gestion des entretiens, ce qui leur permet de s'assurer que toutes les personnes impliquées dans le processus sont informées et préparées.
              </p>
              <p>
              Préparation des entretiens : un logiciel de gestion des entretiens peut fournir aux entreprises des modèles de questions d'entretien, des grilles d'évaluation et des indicateurs de performance pour aider les entreprises à préparer et à mener des entretiens de manière efficace.
              </p>
              <p>
              Enregistrement des entretiens : un logiciel de gestion des entretiens peut permettre aux entreprises d'enregistrer les entretiens, ce qui peut faciliter la revue et la comparaison des entretiens avec les différents candidats.
              </p>
              <p>
              Analyse des données : certains logiciels de gestion des entretiens peuvent collecter et analyser les données des entretiens pour aider les entreprises à prendre des décisions basées sur les données plutôt que sur l'intuition ou le ressenti personnel.
              </p>
              <p>
              Suivi des candidats : un logiciel de gestion des entretiens peut aider les entreprises à suivre l'avancement des candidats dans le processus d'entretien et à prendre des décisions informées sur la prochaine étape.
              </p>
              <p>
              En conclusion, un logiciel de gestion des entretiens peut aider les PME à améliorer leur processus d'entretien en simplifiant la planification et la préparation des entretiens, en facilitant l'enregistrement et la collaboration en temps réel, en permettant l'analyse des données et en aidant à suivre l'avancement des candidats dans le processus d'entretien.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Pme',
  metaInfo: {
    title: "SIRH pour PME",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/pme'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Optimisez votre gestion des ressources humaines avec notre logiciel SIRH pour PME. Facilitez votre recrutement, la gestion des entretiens et la gestion des employés avec notre solution simple et efficace.'
      }
    ]
  },
  components: {Menu, Bottom, PresentationFonction, simplifier},
  data(){
    return {
      menu:"pme"
    }
  }
}
</script>
