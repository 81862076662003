<template>
  <div>
    <section class="acquisition-of-talents-section">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2 v-html="titre"></h2>
          </div>
        </div>
        <div class="row marginTop20 marginBottom20">
          <div class="sous-titre">
            <h3>{{ sousTitre }}</h3>
          </div>
        </div>
        <div class="row" v-if="sens=='droite'">
          <div class="block-image">
            <img v-bind:src="image" alt="logiciel SIRH">
          </div>
          <div class="exempleProduit">
            <div v-for="(m, index) in modules" v-bind:key="m.index" class="exempleProduit-items">
              <detail-function
                  v-bind:module="m"
                  v-bind:numero="index"
                  v-bind:selected="selected"
                  @chargeImage="chargeDeImage"
              ></detail-function>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="exempleProduit">
            <div v-for="(m, index) in modules" v-bind:key="m.index">
                <detail-function
                    v-bind:module="m"
                    v-bind:numero="index"
                    v-bind:selected="selected"
                    @chargeImage="chargeDeImage"
                ></detail-function>
            </div>
          </div>
          <div class="block-image">
            <img v-bind:src="image" v-bind:alt="balise">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import detailFunction from "@/components/detail-function";

export default {
  name: "presentationFonction",
  props: ['titre', 'sousTitre', 'modules', 'sens', 'balise'],
  components: {detailFunction},
  data() {
    return {
      voir: [],
      image: "",
      selected:"",
      index:""
    }
  },
  methods: {
    chargeDeImage(e) {
      this.image = e[0];
      this.selected = e[1];
    },
  }
}
</script>

