<template>
  <nav class="mobile-menu" :class="{ open: menuOpen }">
    <div class="mobile-menu__wrap">
      <router-link
      v-for="(link, linkIndex) in links"
      :key="linkIndex"
      class="mobile-menu__item"
      active-class="active"
      :to="link.path ? link.path : false"
    >
      {{ link.name }}
    </router-link>
    <div class="mobile-menu__buttons">
          <router-link class="mobile-button button-demo" to='/demo' >
          <span>Demande de démo</span></router-link>
    </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Mobilemenu",
  props: ['menuOpen'],
  data() {
    return {
      links: [
        { name: "CoreRh", path: "/sirh/core-rh" },
        { name: "Recrutement", path: "/sirh/logiciel-de-recrutement" },
        { name: "OnBoarding", path: "/sirh/logiciel-integration-onboarding-employes" },
        { name: "Entretiens", path: "/sirh/logiciel-gestion-entretien" },
        { name: "GPEC", path: "/sirh/logiciel-gpec" },
        { name: "GTA", path: "/sirh/logiciel-gestion-des-temps" },
        { name: "Congés", path: "/sirh/logiciel-gestion-des-conges" },
        { name: "Partenaires", path: "/partenaire" },
        { name: "Tarifs", path: "/tarifs/corerh-entretiens" },
        { name: "Sécurité", path: "/securite" },
        { name: "Blog", path: "/actualite-digitalisez-vos-rh" },
      ],
    };
  },
   watch: {
    menuOpen: {
      handler(val) {
        if (val) {
          document.documentElement.style.overflowY ='hidden'
        } else {
          document.documentElement.style.overflowY ='';
        }
      },
    },

  },

};
</script>
<style>
</style>
