<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Logiciel de<br> gestion des<br> remplacements</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gérer les absences de manière simple et rapide.</H2>
            <h3 class="sous-haut-page">
              Mobilisez rapidement votre vivier de remplaçants (collaborateurs internes ou externes de votre CV Thèque).
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fremplacement.svg?alt=media&token=f94f558e-4305-44a3-b09d-73ac346d7554"
                alt="logiciel gestion remplacements">
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="sous-titre marginTop100">
            <h2>En quelques clics<br> vous pouvez créer une mission de remplacement<br>et Inviter des collaborateurs
              internes ou externes</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Lorsqu’un utilisateur accepte, proposez-lui un contrat à partir de vos modèles de contrats</h3>
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-image">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fmission.svg?alt=media&token=648dc458-fc5e-4dcb-a20e-392a9a80a9fd"
                alt="améliorer l'efficience de votre processus de gestion des remplacements">
          </div>
          <div class="block-default-text propos-block-default-text">
            <h2>Fonctionnement</h2>
            <ul>
              <li><strong>Créez une mission en 1 minute</strong>
                <p>Après avoir défini la date et l’heure de début de la mission et la fin de celle-ci, vous pouvez
                  rechercher les profils correspondants et envoyer une invitation aux candidats (et/ou) collaborateurs
                  qui correspondent à votre recherche.
                  Les invitations sont envoyées par mail * (version gratuite) et par SMS ** (inclus dans la version
                  premium du module recrutement)</p>
              </li>
              <li><strong>Sélectionnez un remplaçant</strong>
                <p>Ne perdez plus de temps au téléphone. Lorsqu’un collaborateur accepte la mission, proposez-lui un
                  contrat de travail parmi les modèles que vous avez choisis.</p>
              </li>
              <li><strong>Être notifié à chaque étape</strong>
                <p>Lorsqu’un candidat accepte la mission vous êtes notifié et vous pouvez
                  rédiger de manière automatique (à partir de vos modèles de contrats) le contrat.
                  L’ensemble des éléments nécessaires à l’établissement du contrat sont alors intégrés à celui-ci
                  et le candidat est notifié qu’il peut signer le contrat</p></li>
              <li><strong>Signature digitale du contrat</strong>
                <p>Les candidats peuvent signer le contrat avec une validation simple *
                  (version gratuite) ou en signant le contrat ** (inclus dans la version premium du module
                  recrutement)</p></li>
              <li><strong>Simplifiez encore vos créations de missions</strong>
                <p>En créant des équipes par type de mission vous pouvez
                  accélérer le processus en ciblant des remplaçants « habituels » pour un type de mission
                  (par exemple : je recherche un plongeur pour le weekend).</p></li>
              <li><strong>Gestion des disponibilités des candidats et des collaborateurs</strong>
                <p>Chaque utilisateur a la possibilité de stopper
                  ou d’accepter d’être contacté pour faire des missions. Il peut aussi définir sa mobilité géographique
                  et à quel moment il accepte de faire des missions de remplacement.</p></li>
              <li><strong>Une recherche des ressources possibles à partir d’une multitude de critères</strong>
                <br>- Équipe<br>
                - Mots clés dans les CV<br>
                - Disponibilité géographique<br>
                - Disponibilité temporelle<br>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row">
          <div class="block-default-text">
            <h2>Capitalisez<br> sur vos ressources</h2>
            <ul>
              <li><strong>Gagnez du temps</strong>
                <p>Il n’est plus utile de passer des heures à téléphoner à des collaborateurs internes ou externes
                  pour leur proposer un remplacement (ou une mission). Lorsque vous proposez une mission, ils sont
                  informés par mail et/ou par sms.
                  Ils peuvent refuser ou accepter, vous trouvez ainsi un candidat en quelques minutes.</p>
              </li>
              <li><strong>Maitrisez vos coûts </strong>
                <p>Au-delà du temps gagné en recherche de collaborateurs, vous économisez les frais d’une
                  externalisation de votre recherche.</p>
              </li>
              <li>
                <strong>Mutualisez vos viviers de compétences</strong>
                <p>Créez des équipes sur plusieurs établissements : Vous pouvez ainsi mutualiser les ressources et
                  éviter le recours à des ressources externes alors même que des collaborateurs internes recherchent des
                  missions supplémentaires.</p>
              </li>
              <li>
                Le module de recrutement est une véritable alternative à l’intérim. Une fois votre groupe de
                candidats/collaborateurs constitué, vous n’avez plus besoin de faire appel à l’intérim dans 80% des cas et votre entreprise fait
                donc une économie importante de ses charges salariales.
              </li>
            </ul>
          </div>
          <div class="block-default-image">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fcapitaliser-collaborateurs.svg?alt=media&token=e9c8393c-0a15-45bd-9c39-206dc627be9f"
                alt="capitalisez sur vos collaborateurs">
          </div>
        </div>
      </div>
    </section>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Gagnez du temps, digitalisez l'ensemble du processus des remplacements</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie"
                 alt="les collaborateurs peuvent accepter ou refuser simplement des missions">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Les collaborateurs peuvent accepter ou refuser les missions qui leurs sont proposées simplement.<br>
              Gérer les disponibilités en temps réels.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">Diminution des coûts des remplacements</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie"
                 alt="Visualisez l'état des présences avec le logiciel de gestion des congés">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Gain de temps dans la gestion des remplacements<br>
              Créez des missions et générez un contrat en quelques clics</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";

export default {
  name: 'Remplacement',
  metaInfo: {
    title: "Logiciel de gestion des remplacements - Là où vous en avez besoin",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gestion-des-remplacements'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Simplifiez le gestion de vos remplacements en automatisant la recherche et la création des contrats. Economisez en diminuant votre dépendance à l\'intérim'
      }
    ]
  },
  components: {Menu, Bottom, simplifier},
  data() {
    return {
      menu: "conges"
    }
  }
}
</script>
