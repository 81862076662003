<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Logiciel de gestion<br> des ressources humaines<br> pour Hôtellerie<br> restauration</h1>
              <span class="line-headline"></span>
            </div>
            <H2></H2>
            <h3 class="sous-haut-page">
              Les enjeux RH dans l’hôtellerie et la restauration sont au cœur des préoccupations des restaurateurs et hôtels.
              Selon une étude récente, 85% des professionnels envisagent de changer de secteur d’activité.
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fhcr.jpeg?alt=media&token=68f0748b-38ab-4395-943b-877b40474fcc"
                alt="Intégration et rdd de votre SIRH">
          </div>
        </div>
      </div>
    </section>
    <section class="history-section">
      <div class="container">
        <div class="row responsive-column-reverse">
          <div class="block-default-text propos-block-default-text">
            <h2>Les fonctionnalités proposées</h2>
            <p>
              Pour accompagner les professionnels du secteur de l’hôtellerie et de la restauration nous proposons les fonctionnalités suivantes :
            </p>
            <ul>
            <li>Création d’une CV Thèque simplifiée permettant ensuite des recherches dans celle-ci de manière simple.</li>
            <li>Gestion des remplacements automatisés : A partir de votre CVthèque ou de votre base collaborateurs, vous pouvez proposer des missions et sélectionner les candidats/collaborateurs en quelques clics.</li>
            <li>Pour l’image de l'employeur, le processus d’intégration est aujourd’hui primordial. Vous pouvez ainsi utiliser notre module d’onboarding et permettre de digitaliser la gestion des documents, générer automatiquement les contrats et les faire signer électroniquement.</li>
            <li>Fidélisez vos talents en planifiant des entretiens de qualité où les préparations de chaque côté sont possibles et partageables lorsque les utilisateurs le souhaitent.</li>
          </ul>
          </div>
          <div class="block-default-image">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fmission.svg?alt=media&token=648dc458-fc5e-4dcb-a20e-392a9a80a9fd"
                alt="Capitalisez sur vos précédentes missions">
          </div>
        </div>
      </div>
    </section>
    <avantages></avantages>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import avantages from "@/components/avantages";

export default {
  name: 'Hcr',
  metaInfo: {
    title: "logiciel de gestion des RH pour Hotellerie restauration",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Notre template pour l\'hôtellerie restauration vous permet une mise en place de l\'application rapide. Ainsi en quelques minutes vous pouvez lancer votre campagne d\'entretiens'
      }
    ]
  },
  components: {Menu, Bottom, simplifier, avantages },
  data() {
    return {
      menu: ""
    }
  }
}
</script>
