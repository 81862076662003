<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Gestion des Temps et Activités (GTA):<br/> Optimisez la gestion du temps de travail</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gagnez du temps et optimisez la performance de vos équipes avec notre solution SIRH sur mesure pour PME et ETI, dotée d'un module de gestion des temps et activités performant.</H2>
            <h3 class="sous-haut-page">
              Nos fonctionnalités:<br/>
              - Suivi des temps de travail: Saisie des temps, gestion des absences et des congés.<br/>
              - Suivi des projets: Affectation des temps aux projets et suivi de la performance.<br/>
              - Reporting et analyse: Accédez à des tableaux de bord et des indicateurs clés pour une meilleure gestion du temps de travail.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Fgta.svg?alt=media&token=da53a8ae-6a93-4803-854d-da8811b8c58b" alt="logiciel gestion des temps">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>

    <presentation-fonction
        titre='Suivez les temps et activités<br> de vos collaborateurs'
        sousTitre="Saisissez rapidement les temps par projet, client et visualisez rapidement l'état de vos projets"
        v-bind:modules='["gta-project", "gta-temps"]'
        sens="gauche"
        balise="logiciel gestion des temps"
    ></presentation-fonction>

    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Une gestion des temps performante non seulement pour suivre les temps de travail mais aussi valider le suivi des projets, les
            heures en CII - CIR,...</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie" alt="saisir rapidement vos temps avec notre logiciel de gestion des temps">
            <h3 class="marginTop20">Collaborateurs</h3>
            <p class="simplifieTexte marginTop20">
              Saisie rapide de ses temps de travail
              Accédez aux projets et tâches qui lui sont attribuées.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="logiciel de gestion des temps">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">
              Le module de gestion des temps et activité permet de gérer au mieux la productivité de ses équipes et de planifier les ressources nécessaires par projet.
            </p>
            <p class="simplifieTexte">
              Grâce au module de Temps/Client le suivi de projet devient plus simple, diminuant considérablement le risque d’erreur pour un meilleur pilotage des équipes et une amélioration du management.
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="gérer vos projets et temps associés avec le logiciel de gestion des temps">
            <h3 class="marginTop20">Responsable projets</h3>
            <p class="simplifieTexte marginTop20">Listez les tâches et attribuez les par ressources,
              visualisez l’avancement d’un projet et identifiez les dérives de temps</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>


    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2>Qu'est ce que la gestion des temps<br> et activités ?</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
              <img src="./../assets/images/gta.svg" alt="logiciel gta">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2 class="hauteur25">La GTA pour améliorer et optimiser le suivi des temps de travail</h2>
              <p>
                La gestion des temps et activités est un domaine des ressources humaines (RH) qui a pour but
                d'améliorer et d'optimiser le suivi des temps de travail (heures travaillées par projet ou par clients)
                et des absences des salariés en entreprise.
                La GTA permet principalement de simplifier le traitement ou la gestion de la paie par des exports. En dehors de cela,
                elle représente un levier de productivité important dans l'entreprise dans la mesure où il permet aux
                managers de gérer plus efficacement leurs équipes. La gestion des temps et activités offre également
                la possibilité à l'employeur de proposer des services innovants aux salariés (collaborateurs).
            </p>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Qui est concerné par la gestion des temps et activités ?</h2>
              <p>
                La gestion des temps et activités n'est pas seulement l'apanage des grandes entreprises ou des structures
                d'un secteur spécifique. Elle concerne toutes les organisations salariales. Que vous soyez une entreprise du secteur public
                ou du secteur privé, vous pouvez implémenter notre solution SIRH au sein de vos processus RH.
                Il n'y a pas d'effectif minimum requis pour faire de la gestion des temps et activités.
                Le système s'adapte sans problème à toutes les tailles d'entreprise (PME, TPE…) en prenant une forme simple,
                économique ou complexe selon le cas.
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Pourquoi utiliser un logiciel de GTA ?</h2>
              <p>
                Un logiciel de gestion des temps et activités s'avère indispensable dans une entreprise en raison
                de ses nombreuses fonctionnalités. Ces dernières répondent à des besoins spécifiques du service des ressources humaines.
              <p>
              <h3>Simplifier la gestion des salariés et de la paie</h3>
              <p>Le logiciel de gestion des temps et activités remplace le tableur qu'utilisent traditionnellement les entreprises
                pour gérer le temps de travail des salariés. Cet outil moderne réduit le risque de perte d'informations
                et permet à tous les acteurs de la chaîne de gestion des ressources humaines d'être au courant
                des changements en temps réel. En fonction des données qui y sont intégrées, le logiciel de GTA
                calcule de façon automatique les éléments variables et simplifie l'établissement de la fiche de paie pour chaque type de contrat.
                Il prend en compte les absences, les congés, les RTT… Tout ceci permet à l'entreprise de gagner du temps et d'avoir
                accès à des informations RH fiables et facilement exploitables sur ses collaborateurs.
              </p>
              <h3>Optimiser le management et développer le capital humain de l'entreprise</h3>
              <p>
                Le logiciel de GTA est ce qu'il faut à votre entreprise pour mieux piloter les équipes de salariés et favoriser
                le partage d'informations. Le gain d'efficacité du service des RH qui découle de l'utilisation d'un logiciel
                de gestion des temps et activités permet également d'augmenter la confiance qu'ont les salariés en leurs managers.
                Tout ceci contribue au développement du capital humain de l'entreprise.
              </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Logiciel de gestion des temps et activités : quels objectifs ?</h2>
              <p>
                Le module de GTA intègre un certain nombre de fonctionnalités essentielles à tous les services RH,
                quel que soit le secteur d'activité. En plus de la gestion des horaires des collaborateurs, il permet
                aux responsables projets ou chefs d'équipes de suivre avec précision les heures, les temps de présence,
                les absences (RTT, absences dues à des maladies, congés, congés spéciaux, récupérations,...), les soldes.
              </p>
              <p>
                Il propose un module de suivi des activités et des temps passés par projet, avec un tableau de bord intuitif
                et facilement explorable. Avec le logiciel RH Humatch, vous pouvez même ajouter des estimations à chaque projet afin de voir en temps réel son avancement.
                L'automatisation de la gestion des demandes de congés est également un objectif important que tout logiciel de gestion des temps
                et activités doit permettre à l'entreprise d'atteindre.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Gpec',
  metaInfo: {
    title: "Module gestion des temps et activités (GTA) : Suivez les temps de travail de vos collaborateurs | Humatch",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-gestion-des-temps'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Notre logiciel de gestion des congés et absences vous aide à gérer les demandes et les autorisations de congé de vos employés en toute simplicité. Suivez les jours de congé restants et gérez les autorisations en temps réel. Obtenez une vue d\'ensemble complète de vos absences.'
      }
    ]
  },
  data(){
    return {
      menu:"gta"
    }
  },
  components: { PresentationFonction, Menu, Bottom, simplifier}
}
</script>
