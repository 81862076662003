<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Module Recrutement<br/> Optimisez vos processus et trouvez les meilleurs talents</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Gagnez du temps et de l'efficacité avec notre solution SIRH sur mesure pour PME et ETI, dotée d'un module de recrutement complet et performant.</H2>
            <h3 class="sous-haut-page">
              <strong>Nos fonctionnalités:</strong><br/>
              - Diffusion d'annonces: Publiez vos offres d'emploi sur les plateformes les plus pertinentes et optimisez votre visibilité.<br/>
              - Collecte de candidatures: Centralisez les candidatures et recevez des notifications en temps réel.<br/>
              - Suivi des candidats: Organisez et comparez les profils, filtrez par mots-clés et notez les candidats.<br/>
              - Planification des entretiens: Gagnez du temps en planifiant et en centralisant les entretiens.<br/>
             - Gestion des offres: Envoyez et suivez les offres d'emploi, et collectez les signatures électroniques.<br/>
            </h3>
          </div>
          <div class="fonction-image marginTop100">
            <img src="https://firebasestorage.googleapis.com/v0/b/blog-humatch.appspot.com/o/site-internet%2Frecrutement.svg?alt=media&token=9476458b-2ad5-4c6b-90a7-d908910de70c" alt="ATS">
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <presentation-fonction
        titre='Recrutez efficacement<br> et trouvez rapidement les candidats idéals pour votre entreprise '
        sousTitre="Notre ATS permet de gérer l'ensemble du workflow de la création d'une offre à l'intégration du candidat"
        v-bind:modules='["recrutement-offre", "recrutement-cv"]'
        sens="gauche"
        balise="recrutez rapidement avec notre outil de sourcing"
    ></presentation-fonction>
    <div class="marginBottom20 marginTop20"></div>
    <presentation-fonction
        titre='Personnalisez<br> votre workflow de recrutement'
        sousTitre="Paramétrez notre ATM à votre image et en fonction de votre métier"
        v-bind:modules='["recrutement-candidat", "recrutement-integration",]'
        sens="droite"
        balise="adaptez l'application de recrutement à votre organisation"
    ></presentation-fonction>
    <div class="marginBottom20 marginTop20"></div>
    <section class="acquisition-of-talents-section boxAvantage">
      <div class="container">
        <div class="row">
          <div class="sous-titre">
            <h2>Tous les avantages<br>dans une solution tout-en-un</h2>
          </div>
        </div>
        <div class="row marginBottom20 marginTop20">
          <div class="sous-titre sousTitreSimplifie">
            <h3>Notre application de recrutement vous permet de gagner en efficacité et de fluidifier la communication entre
            le service recrutement et les managers de votre entreprise</h3>
          </div>
        </div>
        <div class="row padding80">
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-recruteur.svg" class="iconSimplifie" alt="une application pour conserver le contact avec les candidats">
            <h3 class="marginTop20">Service Recrutement</h3>
            <p class="simplifieTexte marginTop20">Une maîtrise optimisée du cycle de recrutement qui permet de garder le
              contact de chaque candidat·e et d’élargir votre vivier de talents à disposition
            </p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-drh.svg" class="iconSimplifie" alt="publiez des annonces pertinentes avec notre logiciel de gestion des recrutement">
            <h3 class="marginTop20">Service RH</h3>
            <p class="simplifieTexte marginTop20">Pour le service RH, c’est l’assurance que le contenu des annonces correspond aux
              caractéristiques des fonctions, et au descriptif de l’entreprise.
              C’est plus de cohérence avec l’harmonisation des différentes annonces publiées.</p>
          </div>
          <div class="troisColumn padding20">
            <img src="./../assets/images/av-manager.svg" class="iconSimplifie" alt="impliquez les managers dans les recrutements">
            <h3 class="marginTop20">Manager</h3>
            <p class="simplifieTexte marginTop20">Le manager peut voir dans l'application les différents candidats,
            et peut compléter des questionnaires. Il peut transmettre un avis aux services RH.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <section class="acquisition-of-talents-section boxSimplifie">
      <div class="container ">
        <div class="pourquoi">
          <div class="row">
            <div class="sous-titre">
              <h2 id="logiciel">Le processus de recrutement est un élément clé de la gestion des ressources humaines.</h2>
            </div>
          </div>
          <div class="row marginBottom20">
            <div class="image-explication">
                <img src="./../assets/images/recrutement-efficace.svg" alt="Permettre un processus de détection des talents efficace">
            </div>
            <div class="presentation-text textExplication marginTop100">
              <h2>Les atouts d'un logiciel de recrutement pour votre entreprise incluent:</h2>
              <p>
                <ul>
                  <li>- Automatisation des tâches administratives: Notre logiciel de recrutement automatise les tâches administratives fastidieuses telles que la diffusion d'annonces, la collecte de candidatures et le suivi des candidats.</li>
                  <li>- Centralisation des informations sur les candidats: En centralisant toutes les informations sur les candidats dans une seule plateforme, vous pouvez mieux suivre les candidats tout au long du processus de recrutement et prendre des décisions plus rapides.</li>
                  <li>- Amélioration de la collaboration: Notre logiciel de recrutement peut améliorer la collaboration entre les membres de l'équipe en permettant un suivi en temps réel et une communication efficace.</li>
                  <li>- Personnalisation pour répondre aux besoins de votre entreprise: Vous pouvez personnaliser le logiciel de recrutement pour répondre aux besoins spécifiques de votre entreprise.</li>
                  <li>- Génération de rapports en temps réel: Vous pouvez générer des rapports sur les tendances en matière de recrutement pour planifier les ressources de manière plus efficace.</li>
                  <li>- Économie de temps et de ressources: En automatisant les tâches administratives et en streamlinant le processus de recrutement, vous pouvez économiser du temps et des ressources.</li>
                  <li>- Meilleure prise de décision en matière de recrutement: Un logiciel de recrutement peut inclure des fonctionnalités telles que la gestion des compétences et la gestion des références pour aider à prendre des décisions éclairées en matière de recrutement.</li>
                </ul>
              </p>
            </div>
          </div>
          <div class="row marginBottom20 marginTop20">
            <div class="troisColumn padding10 textExplication">
                <h2 class="hauteur25">Un logiciel de recrutement<br> de quoi s'agit-il ?</h2>
                <p>
                  Une application de recrutement est <strong>une composante du SIRH</strong> (Système d'information de gestion des Ressources Humaines)
                  qui accompagne le département des ressources humaines d'une entreprise durant toutes les étapes d'un processus de recrutement.
                  En anglais, il est connu sous le nom de Applicant Tracking System ou plus simplement ATS. Un outil de sourcing peut se
                  présenter sous la forme d'une application hébergée en local ou d'une plateforme Saas de gestion de recrutement en ligne.
                </p>
                <p>
                  Dans les deux cas, l'objectif reste le même : aider les recruteurs à gérer avec facilité et simplicité leur base de talents,
                  tout en évitant les pertes de temps. Le logiciel de gestion des recrutements que Humatch met à la disposition de ses clients professionnels
                  est un outil de gestion de candidatures entièrement dématérialisé et adapté à tous les types d'organisations. Moderne et
                  intuitif, ce module de SIRH intègre toutes les fonctionnalités dont les ressources humaines ont besoin pour recruter de manière
                  fluide et détecter avec facilité les meilleurs profils. Il présente également plusieurs avantages que vous pouvez découvrir
                  en demandant une démo sur notre site.
                </p>
            </div>
            <div class="troisColumn padding10 textExplication">
              <h2 class="hauteur25">Les fonctionnalités clés d'un bon logiciel ATS</h2>
              <p>
                Il existe une multitude de logiciels de recrutement sur le marché actuellement. Les modules et avantages proposés peuvent donc
                varier d'un prestataire à l'autre. Il y a toutefois certaines fonctionnalités clés que <strong>tout logiciel d'acquisition des talents
                performant</strong> doit obligatoirement proposer :
              </p>
              <ul>
                <li>multidiffusion automatique des annonces d'emploi sur différents sites spécialisés,</li>
                <li>centralisation de toutes les candidatures après la diffusion des offres d'emploi, avec possibilité de tri et de sélection,</li>
                <li>reporting des données et statistiques.</li>
              </ul>
              <p>
                Pour aller plus loin, certains outils de gestion de recrutement comme <strong>notre ATS </strong> proposent une
                fonctionnalité de CVthèque. Elle permet de garder un historique des candidatures pertinentes lors des recrutements afin de
                pouvoir y avoir accès facilement ultérieurement. La possibilité de répondre individuellement à chaque candidat pour notifier
                une prise de rendez-vous ou un refus est également une fonctionnalité très pratique pour les entreprises.
              </p>
              <p>
                Si vous êtes une entreprise en pleine croissance, il vaut mieux opter pour un logiciel complet en mode Saas qui offre une large
                palette de fonctionnalités pratiques. Il s'agit par exemple de la multidiffusion des offres, des réponses automatiques aux
                différents candidats à la recherche d'un emploi, de la planification des entretiens.
              </p>
            </div>
            <div class="troisColumn padding20 textExplication">
              <h2 class="hauteur25">Pourquoi un logiciel du recrutement en entreprise ?</h2>
              <p>
                Se doter d'un outil de sourcing n'est désormais plus une option pour la plupart des entreprises. C'est <strong>une
                nécessité</strong> pour plusieurs raisons. L'utilisation d'un tel sofware est tout d'abord indispensable pour
                réduire les tâches chronophages que les recruteurs doivent prendre en charge durant un processus d'embauche en entreprise. Les
                cadres du service des ressources humaines peuvent ainsi se concentrer efficacement sur la détection des talents et l'évaluation
                des compétences des meilleures candidatures. Cela permet de garantir un recrutement plus qualitatif et plus pointu.
              </p>
              <p>
                L'utilisation d'un logiciel de recrutement est ensuite essentielle pour centraliser les communications en lien avec le recrutement et le
                traitement des candidatures (fluidification du processus). Avec un logiciel doté d'une fonctionnalité de &laquo; travail
                collaboratif &raquo; comme Humatch, les recruteurs peuvent créer des notes, travailler en équipe, et partager des informations
                relatives à certaines candidatures.
              </p>
              <p>
                L'utilisation d'une application performante contribue aussi à <strong>l'amélioration de la marque employeur</strong>.
                La possibilité offerte aux candidats de postuler très facilement ainsi que la gestion dynamique et professionnelle des
                candidatures vont donner envie aux meilleurs talents de travailler chez vous. Tout le monde y trouve son bonheur : les candidats
                à la recherche d'un emploi, les managers, le recruteur et les cadres du département des ressources humaines.
              </p>
              <p>
                Par ses nombreuses fonctionnalités pratiques et son efficacité dans la détection des talents et des bons profils, notre logiciel
                de recrutement influence directement les performances de l'entreprise. Notre module vous permet de recruter un personnel
                qualifié et d'avoir à portée de main des profils intéressants pour combler d'éventuels besoins en compétences dans le futur.
                N'hésitez pas à choisir Humatch si vous recherchez <strong>un module de recrutement complet</strong> pour insuffler une
                nouvelle dynamique à votre entreprise grâce au recrutement de nouveaux candidats. Notre système d'information de gestion des
                ressources humaines intègre également plusieurs autres modules qui peuvent être utiles dans la gestion de votre personnel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="marginBottom20 marginTop20"></div>
    <simplifier></simplifier>
    <div class="marginBottom20 marginTop20"></div>
    <Bottom></Bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";
import simplifier from "@/components/simplifier";
import PresentationFonction from "@/components/presentationFonction";

export default {
  name: 'Recrutement',
  metaInfo: {
    title: "ATS - Simplifiez vos recrutements avec notre module de recrutement",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/sirh/logiciel-de-recrutement'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Optimisez votre processus de recrutement avec notre ATS puissant et facile à utiliser. Automatisez les tâches administratives, suivez les candidats en un seul endroit et trouvez les talents les plus pertinents pour votre entreprise.'
      }
    ]
  },
  components: {Menu, Bottom, PresentationFonction, simplifier},
  data(){
    return {
      menu:"recrutement"
    }
  }
}
</script>
