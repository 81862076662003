<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <img src="./../assets/images/error.jpg" class="img-responsive" alt="oups le café est pour nous">
        </div>
      </div>
    </section>
    <bottom></bottom>
  </div>
</template>

<script>

import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";

export default {
  name: "NotFound",
  metaInfo: {
    title: "Oups 404",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Oups'
      }
    ]
  },
  components: {Menu, Bottom}
}
</script>

<style scoped>

</style>
