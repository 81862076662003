<template>
  <div>
    <Menu></Menu>
    <section class="presentation-section">
      <div class="container">
        <div class="row">
          <div class="presentation-text">
            <div class="headline">
              <h1>Simplifier la gestion RH et gagner en efficacité</h1>
              <span class="line-headline"></span>
            </div>
            <H2>Venez découvrir toutes les fonctionnalités de notre solution dans une démonstration gratuite et sans engagement.</H2>

          </div>
          <div class="fonction-demo marginTop20">
            <!-- Start of Meetings Embed Script -->
            <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/humatch/demo?embed=true"></div>
            <!-- End of Meetings Embed Script -->
          </div>
        </div>
      </div>
    </section>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Bottom from "@/components/Bottom";



export default {
  name: 'Demo',
  metaInfo: {
    title: "Démo gratuite sans engagement",
    link: [
      {
        rel: 'canonical', href: 'https://humatch.fr/demo'
      }
    ],
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Découvrez notre solution SIRH sans engagement'
      }
    ]
  },
  components: {Menu, Bottom},
  data(){
    return {
      menu:"demo"
    }
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js')
    document.head.appendChild(recaptchaScript)
  }
}
</script>


