<template>
<div v-on:mouseleave="onChange">
  <div class="submenu">
    <div class="container">
      <router-link to='/sirh/logiciel-de-recrutement' class="blockMenu first">
        <img src="./../assets/images/ballon-recrutement.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Recrutement</strong>
          <span class="sousTitreMenu">Acquisition de talents et suivi
                      des candidatures</span>
        </div>
      </router-link>
      <router-link to='/sirh/logiciel-gestion-entretien' class="blockMenu second">
        <img src="./../assets/images/IconEntretien.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Entretiens</strong>
          <span class="sousTitreMenu">Gestion des entretiens et suivi des objectifs</span>
        </div>
      </router-link>
      <router-link to='/sirh/logiciel-integration-onboarding-employes' class="blockMenu third">
        <img src="./../assets/images/IconOnBoarding.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>OnBoarding</strong>
          <span class="sousTitreMenu">Gestion des nouveaux collaborateurs</span>
        </div>
      </router-link>

      <router-link to='/sirh/core-rh' class="blockMenu forth">
        <img src="./../assets/images/IconCore.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>CoreRh</strong>
          <span class="sousTitreMenu">Gestion du personnel</span>
        </div>
      </router-link>
      <router-link to='/sirh/logiciel-gpec' class="blockMenu firth">
        <img src="./../assets/images/IconGpec.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>GPEC</strong>
          <span class="sousTitreMenu">Développement des compétences et des talents</span>
        </div>
      </router-link>
      <router-link to='/sirh/logiciel-gestion-des-temps' class="blockMenu six">
        <img src="./../assets/images/IconGta.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Temps de travail</strong>
          <span class="sousTitreMenu">Suivi d'activité des collaborateurs et de leurs heures de travail</span>
        </div>
      </router-link>
      <router-link to='/sirh/logiciel-gestion-des-conges' class="blockMenu height">
        <img src="./../assets/images/IconConges.svg" class="iconSousMenu">
        <div class="textMenu">
          <strong>Congés et absences</strong>
          <span class="sousTitreMenu">Optimisation de la gestion des congés et des absences</span>
        </div>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "sousmenufonctions",
  methods: {
    onChange(){
      this.$emit('change');
    }
  }
}
</script>

<style scoped>
.submenu {
  index: 1;
  width: 658px;
  height: 514px;
  background-color: #FAF6FE;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #99A1C7;
  position: fixed;
  margin-top: 80px;
  margin-left: -180px;
}

.subsection {
  position: absolute;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #496C7C;
}

.subSection1 {
  width: 98px;
  height: 24px;
  left: 48px;
  top: 32px;
}

.subSection2 {
  width: 167px;
  height: 24px;
  left: 361px;
  top: 32px;
}

.blockMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 281px;
  height: 96px;
  background: white;
  border-radius: 8px;
}

.first {
  top: 72px;
  left: 32px;
}

.second {
  top: 176px;
  left: 32px;
}

.third {
  top: 280px;
  left: 32px;
}

.forth {
  top: 72px;
  left: 345px;
}

.firth {
  top: 176px;
  left: 345px;
}

.six {
  top: 280px;
  left: 345px;
}

.seven {
  top: 384px;
  left: 345px;
}

.height {
  top: 384px;
  left: 32px;
}

.blockMenu:hover {
  /* Light/Violet */
  background: #F4F5FE;
  border-radius: 8px;
}

.textMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 205px;
  height: 64px;
  left: 60px;
  top: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;
}

.sousTitreMenu {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.iconSousMenu {
  /* Icon */
  position: static;
  width: 28px;
  height: 28px;
  left: 6px;
  top: 48px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.flecheBas {
  position: static;
  width: 12px;
  height: 12px;
  left: calc(50% - 12px / 2 + 58px);
  top: calc(50% - 12px / 2);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
  background: url("./../assets/images/fleche-bas.svg");
}
</style>
